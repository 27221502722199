<template>
  <div>
    <ul
      v-if="!isLoading"
      ref="serviceListContainer"
      class="vertical dropdown menu mention--box"
      :style="{ top: getTopPadding() + 'rem' }"
    >
      <li
        v-for="(item, index) in items"
        :id="`mention-item-${index}`"
        :key="item.key"
        :class="{ active: index === selectedIndex }"
        @click="onSelectService(item)"
        @mouseover="onHover(index)"
      >
        <a class="canned-response">
          <span class="text-truncate canned-response-message">
            <strong>{{ item.title }}</strong> - {{ item.description }}
          </span>
        </a>
      </li>
    </ul>
    <ul
      v-if="isLoading"
      class="vertical dropdown menu mention--box"
      :style="{ top: getTopPadding() + 'rem' }"
    >
      <li>
        {{ $t('CONVERSATION.SEARCH.LOADING_MESSAGE') }}
      </li>
    </ul>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import BookingAPI from '../../../api/booking';

export default {
  props: {
    onSelectService: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      items: [],
      selectedIndex: 0,
      isLoading: true,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: 'getCurrentUser',
      accountId: 'getCurrentAccountId',
    }),
  },
  watch: {
    selectedIndex() {
      const container = this.$refs.serviceListContainer;
      const item = container.querySelector(
        `#mention-item-${this.selectedIndex}`
      );
      if (item) {
        const itemTop = item.offsetTop;
        const itemBottom = itemTop + item.offsetHeight;
        const containerTop = container.scrollTop;
        const containerBottom = containerTop + container.offsetHeight;
        if (itemTop < containerTop) {
          container.scrollTop = itemTop;
        } else if (itemBottom + 34 > containerBottom) {
          container.scrollTop = itemBottom - container.offsetHeight + 34;
        }
      }
    },
  },
  mounted() {
    const { support_email } = this.$store.getters['accounts/getAccount'](
      this.accountId
    );
    BookingAPI.getEventTypes(support_email).then(({ data }) => {
      this.isLoading = false;
      this.items = data.eventTypesUrl;
    });
  },
  methods: {
    getTopPadding() {
      if (this.items.length <= 4) {
        return -(this.items.length * 2.9 + 1.7);
      }
      return -28;
    },
    onHover(index) {
      this.selectedIndex = index;
    },
  },
};
</script>

<style scoped lang="scss">
.mention--box {
  background: var(--white);
  border-radius: var(--border-radius-normal);
  border-top: 1px solid var(--color-border);
  box-shadow: var(--shadow-medium);
  left: 0;
  max-height: 28rem;
  overflow: auto;
  padding: var(--space-small) var(--space-small) 0;
  position: absolute;
  width: 100%;
  z-index: 100;
  .canned-response {
    display: flex;
    align-items: center;
    gap: 10px;
    .canned-response-image {
      width: 30px;
      height: 30px;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      border-radius: 5px;
    }
    .canned-response-message {
      width: calc(100% - 30px);
    }
  }
  .active a {
    background: var(--w-500);
  }
}

.canned-item__button::v-deep .button__content {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
