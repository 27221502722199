<template>
  <woot-modal size="small" :show="show" :on-close="() => $emit('close')">
    <woot-modal-header :header-title="$t('AUTOMATION.CREATE_MODAL.TITLE')" />
    <div ref="createContainer" class="create-container">
      <div class="create-messageType-model">
        <div class="create-Group">
          <div
            :class="activeClass('action')"
            @click="() => toggleSelected('action')"
          >
            <div class="create-box" />
            <div class="create-text">{{ $t('MESSAGE_TYPE.ACTION') }}</div>
          </div>
          <div
            v-if="messageTypeFeatureEnable"
            :class="activeClass('card_message')"
            @click="() => toggleSelected('card_message')"
          >
            <div class="create-box" />
            <div class="create-text">{{ $t('MESSAGE_TYPE.CARD_MESSAGE') }}</div>
          </div>
          <div
            v-if="messageTypeFeatureEnable"
            :class="activeClass('quick_reply')"
            @click="() => toggleSelected('quick_reply')"
          >
            <div class="create-box" />
            <div class="create-text">{{ $t('MESSAGE_TYPE.QUICK_REPLY') }}</div>
          </div>
        </div>
        <woot-button
          :is-disabled="cardSelected === ''"
          class="create-select-button"
          @click="onSubmit"
        >
          {{ $t('AUTOMATION.HEADER_BTN_TXT') }}
        </woot-button>
      </div>
    </div>
  </woot-modal>
</template>

<script>
export default {
  props: {
    show: Boolean,
    close: {
      type: Function,
      default: () => {},
    },
    messageTypeFeatureEnable: { type: Boolean, default: false },
  },
  data() {
    return {
      cardSelected: 'action',
    };
  },
  methods: {
    activeClass(type) {
      return {
        'create-wrap': true,
        active: type === this.cardSelected,
      };
    },
    toggleSelected(type) {
      this.cardSelected = type;
    },
    onSubmit() {
      if (this.cardSelected === 'action') {
        this.$router.push({
          name: 'new_automation',
        });
      } else {
        this.$router.push({
          name: 'new_message_types',
          query: {
            type: this.cardSelected,
          },
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.create-container {
  display: flex;
  height: 390px;
}
.create-Group {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
  margin-top: 32px;
}
.create-wrap {
  display: flex;
  flex-direction: column;
  padding: 16px 16px 28px 16px;
  align-items: center;
  gap: 16px;
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  background: #fff;
  &.active {
    border: 2px solid #1f93ff;
  }
}

.create-box {
  width: 120px;
  height: 120px;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  opacity: 0.20000000298023224;
  background: #1f93ff;
}

.create-text {
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
}

.create-select-button {
  display: flex;
  height: 32px;
  padding: 8px 16px;
  align-items: center;
  gap: 8px;
  border-radius: 20px;
  background: #1f93ff;
  font-size: 14px;
  font-style: normal;
  cursor: pointer;
  margin-top: 5rem;
}
.create-messageType-model {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
