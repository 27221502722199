<template>
  <div ref="mentionsListContainer" class="mention--box">
    <input
      v-if="canSearch"
      v-model="keyword"
      type="text"
      placeholder="Type to search"
      @input="onInput"
    />
    <div v-if="items.length">
      <ul class="vertical dropdown menu">
        <woot-dropdown-item
          v-for="(item, index) in items"
          :id="`mention-item-${index}`"
          :key="item.key"
          @mouseover="onHover(index)"
        >
          <woot-button
            class="canned-item__button"
            :variant="index === selectedIndex ? '' : 'clear'"
            :class="{ active: index === selectedIndex }"
            @click="onListItemSelection(index)"
          >
            <img
              v-if="item.images && item.images.length"
              :src="getCannedImage(item)"
              class="canned-response-image"
            />
            <strong>{{ item.label }}</strong> - {{ item.description }}
          </woot-button>
        </woot-dropdown-item>
      </ul>
    </div>
    <div v-else class="pb-4 text-center">
      {{ $t('SEARCH.EMPTY_STATE_FULL', { query: keyword }) }}
    </div>
  </div>
</template>

<script>
import mentionSelectionKeyboardMixin from './mentionSelectionKeyboardMixin';
export default {
  mixins: [mentionSelectionKeyboardMixin],
  props: {
    items: {
      type: Array,
      default: () => {},
    },
    canSearch: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedIndex: 0,
      keyword: '',
    };
  },
  watch: {
    items(newItems) {
      if (newItems.length < this.selectedIndex + 1) {
        this.selectedIndex = 0;
      }
    },
    selectedIndex() {
      const container = this.$refs.mentionsListContainer;
      const item = container.querySelector(
        `#mention-item-${this.selectedIndex}`
      );
      if (item) {
        const itemTop = item.offsetTop;
        const itemBottom = itemTop + item.offsetHeight;
        const containerTop = container.scrollTop;
        const containerBottom = containerTop + container.offsetHeight;
        if (itemTop < containerTop) {
          container.scrollTop = itemTop;
        } else if (itemBottom + 34 > containerBottom) {
          container.scrollTop = itemBottom - container.offsetHeight + 34;
        }
      }
    },
  },
  methods: {
    getCannedImage(cannedResponse) {
      return cannedResponse.images && cannedResponse.images.length
        ? cannedResponse.images[0].file_url
        : '';
    },
    getTopPadding() {
      if (this.items.length <= 4) {
        return -(this.items.length * 2.9 + 1.7);
      }
      return -28;
    },
    handleKeyboardEvent(e) {
      this.processKeyDownEvent(e);
    },
    onHover(index) {
      this.selectedIndex = index;
    },
    onListItemSelection(index) {
      this.selectedIndex = index;
      this.onSelect();
    },
    onSelect() {
      this.$emit('mention-select', this.items[this.selectedIndex]);
    },
    onInput(e) {
      this.keyword = e.target.value;
      clearTimeout(this.debounce);
      this.debounce = setTimeout(async () => {
        this.$emit('on-search', this.keyword);
      }, 500);
    },
  },
};
</script>

<style scoped lang="scss">
.mention--box {
  @apply bg-white dark:bg-slate-700 rounded-md overflow-auto absolute w-full z-20 pt-2 px-2 pb-0 shadow-md left-0 bottom-full max-h-[9.75rem] border-t border-solid border-slate-75 dark:border-slate-800;

  .dropdown-menu__item:last-child {
    @apply pb-1;
  }

  .active {
    @apply text-white dark:text-white;

    &:hover {
      @apply bg-woot-700 dark:bg-woot-700;
    }
  }

  .button {
    @apply transition-none h-8 leading-[1.4];
  }
}

.canned-item__button::v-deep .button__content {
  @apply overflow-hidden text-ellipsis whitespace-nowrap;
}

.canned-response-image {
  height: 20px;
}
</style>
