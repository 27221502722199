<template>
  <tr>
    <td>
      <div class="flex gap-1">
        <woot-button
          v-if="sort"
          icon="chevron-up"
          color-scheme="secondary"
          size="tiny"
          variant="smooth"
          :disabled="index === 0"
          @click="$emit('move-up', index)"
        />
        <woot-button
          v-if="sort"
          icon="chevron-down"
          color-scheme="secondary"
          size="tiny"
          variant="smooth"
          :disabled="index === records.length - 1"
          @click="$emit('move-down', index)"
        />
        <div class="overflow-auto">
          {{ macro.name }}
        </div>
      </div>
    </td>
    <td>
      <div class="avatar-container">
        <thumbnail :username="macro.created_by.name" size="24px" />
        <span>{{ macro.created_by.name }}</span>
      </div>
    </td>
    <td>
      <div class="avatar-container">
        <thumbnail :username="macro.updated_by.name" size="24px" />
        <span>{{ macro.updated_by.name }}</span>
      </div>
    </td>
    <td>{{ visibilityLabel }}</td>
    <td class="button-wrapper">
      <router-link :to="addAccountScoping(`settings/macros/${macro.id}/edit`)">
        <woot-button
          v-tooltip.top="$t('MACROS.EDIT.TOOLTIP')"
          variant="smooth"
          size="tiny"
          color-scheme="secondary"
          class-names="grey-btn"
          icon="edit"
        />
      </router-link>
      <woot-button
        v-tooltip.top="$t('MACROS.DELETE.TOOLTIP')"
        variant="smooth"
        color-scheme="alert"
        size="tiny"
        icon="dismiss-circle"
        class-names="grey-btn"
        @click="$emit('delete')"
      />
    </td>
  </tr>
</template>

<script>
import Thumbnail from 'dashboard/components/widgets/Thumbnail.vue';
import accountMixin from 'dashboard/mixins/account.js';
export default {
  components: {
    Thumbnail,
  },
  mixins: [accountMixin],
  props: {
    index: {
      type: Number,
      default: 0,
    },
    macro: {
      type: Object,
      required: true,
    },
    sort: {
      type: Boolean,
      default: false,
    },
    records: {
      type: Array,
      default: () => [],
    },
    moveUp: {
      type: Function,
      default: () => {},
    },
    moveDown: {
      type: Function,
      default: () => {},
    },
  },
  computed: {
    visibilityLabel() {
      return this.macro.visibility === 'global'
        ? this.$t('MACROS.EDITOR.VISIBILITY.GLOBAL.LABEL')
        : this.$t('MACROS.EDITOR.VISIBILITY.PERSONAL.LABEL');
    },
  },
};
</script>

<style scoped lang="scss">
.avatar-container {
  display: flex;
  align-items: center;

  span {
    margin-left: var(--space-small);
    margin-right: var(--space-small);
  }
}
</style>
