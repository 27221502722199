import alertMixin from 'shared/mixins/alertMixin';

export default {
  mixins: [alertMixin],
  data() {
    return {
      sort: false,
    };
  },
  methods: {
    toggleSort() {
      this.sort = !this.sort;
    },
    moveUp(index) {
      if (index > 0) {
        const movedRow = this.records.splice(index, 1)[0];
        this.records.splice(index - 1, 0, movedRow);
      }
    },
    moveDown(index) {
      if (index < this.records.length - 1) {
        const movedRow = this.records.splice(index, 1)[0];
        this.records.splice(index + 1, 0, movedRow);
      }
    },
    updateOrder() {
      this.$store
        .dispatch(`${this.tableName}/updateOrder`, this.records)
        .then(() => {
          this.showAlert(this.$t(this.successMessage));
        })
        .catch(() => {
          this.showAlert(this.$t(this.errorMessage));
        })
        .finally(() => {
          this.sort = false;
        });
    },
  },
  beforeRouteLeave(_to, _from, next) {
    if (!this.sort) {
      next();
    } else {
      this.showAlert(this.$t('SORTING.WARNING_SAVE_BEFORE'));
    }
  },
};
