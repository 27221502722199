<!-- <template>
  <div class="message-type-list">
    <ul
      ref="messageTypeContainer"
      class="vertical dropdown menu mention--box"
      :style="{ top: getTopPadding() + 'rem' }"
    >
      <input
        v-model="keyword"
        type="text"
        placeholder="Type to search"
        @input="debounceSearch"
      />
      <li
        v-for="(record, index) in records"
        :id="`mention-item-${index}`"
        :key="record.id"
        :class="{ active: index === selectedIndex }"
        @click="onSelectMessageType(record)"
        @mouseover="onHover(index)"
      >
        <a class="message-type-item">
          <span class="text-truncate">
            {{ record.title }}
          </span>
          <span :class="'type-item ' + record.type">{{
            getActionTypeTag(record.type)
          }}</span>
        </a>
      </li>
    </ul>
    <p v-if="uiFlags.isFetching">Loading</p>
  </div>
</template> -->
<template>
  <mention-box
    :items="items"
    :can-search="true"
    @on-search="debounceSearch"
    @mention-select="handleMentionClick"
  />
</template>

<script>
import { mapGetters } from 'vuex';
import { getActionTypeName } from '../../../../widget/helpers/constants';
import MentionBox from '../mentions/MentionBox.vue';

export default {
  components: { MentionBox },
  props: {
    onSelectMessageType: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      keyword: '',
      selectedIndex: 0,
    };
  },
  computed: {
    ...mapGetters({
      records: 'messageTypes/getMessageTypes',
      uiFlags: 'messageTypes/getUIFlags',
    }),
    items() {
      return this.records.map(record => ({
        id: record.id,
        label: record.title,
        description: getActionTypeName(record.type),
      }));
    },
  },
  watch: {
    selectedIndex() {
      const container = this.$refs.messageTypeContainer;
      const item = container.querySelector(
        `#mention-item-${this.selectedIndex}`
      );
      if (item) {
        const itemTop = item.offsetTop;
        const itemBottom = itemTop + item.offsetHeight;
        const containerTop = container.scrollTop;
        const containerBottom = containerTop + container.offsetHeight;
        if (itemTop < containerTop) {
          container.scrollTop = itemTop;
        } else if (itemBottom + 34 > containerBottom) {
          container.scrollTop = itemBottom - container.offsetHeight + 34;
        }
      }
    },
  },
  mounted() {
    this.$store.dispatch('messageTypes/get', { per: 10 });
  },
  methods: {
    debounceSearch(value) {
      this.$store.dispatch('messageTypes/search', value);
    },
    getActionTypeTag(tagName) {
      return getActionTypeName(tagName);
    },
    handleMentionClick(item = {}) {
      const selectedItem = this.records.find(record => record.id === item.id);
      this.$emit('on-select-message-type', selectedItem);
    },
  },
};
</script>

<style lang="scss" scoped>
.mention--box {
  border-radius: var(--border-radius-normal);
  border-top: 1px solid var(--color-border);
  box-shadow: var(--shadow-medium);
  left: 0;
  max-height: 28rem;
  overflow: scroll;
  padding: var(--space-small) var(--space-small) 0;
  position: absolute;
  width: 100%;
  z-index: 100;

  input {
    margin-bottom: 10px;
  }
  .message-type-item {
    color: #6e6f73;
    display: flex;
    align-items: center;
    gap: 10px;

    .type-item {
      border-radius: 20px;
      padding: 4px 8px;
      font-size: 10px;
      &.card_message,
      &.carousel {
        background: rgba(31, 147, 255, 0.15);
        color: #1f93ff;
      }
      &.quick_reply {
        background: rgba(0, 162, 0, 0.15);
        color: #00a200;
      }
    }
  }
  .active a {
    background: var(--w-500);
    color: white;
  }
}
</style>
