import * as MutationHelpers from 'shared/helpers/vuex/mutationHelpers';
import types from '../mutation-types';
import EventTypesAPI from '../../api/eventTypes';

export const state = {
  records: [],
  uiFlags: {
    isFetchingItem: false,
    isFetching: false,
  },
};

export const getters = {
  getEventTypes($state) {
    return $state.records;
  },
  getUIFlags($state) {
    return $state.uiFlags;
  },
};

export const actions = {
  get: async function getEventTypes({ commit }) {
    commit(types.SET_EVENT_TYPES_UI_FLAG, { isFetching: true });
    try {
      const response = await EventTypesAPI.get();
      commit(types.SET_EVENT_TYPES, response.data.payload);
    } catch (error) {
      // Ignore error
    } finally {
      commit(types.SET_EVENT_TYPES_UI_FLAG, { isFetching: false });
    }
  },
};

export const mutations = {
  [types.SET_EVENT_TYPES_UI_FLAG]($state, data) {
    $state.uiFlags = {
      ...$state.uiFlags,
      ...data,
    };
  },
  [types.SET_EVENT_TYPES]: MutationHelpers.set,
};

export default {
  namespaced: true,
  actions,
  state,
  getters,
  mutations,
};
