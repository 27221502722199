<template>
  <woot-modal :show="show" :on-close="() => $emit('close')" size="modal-big">
    <woot-modal-header :header-title="$t('AUTOMATION.PREVIEW.TITLE')" />
    <div class="mode-container">
      <label>
        <div class="mode-status">
          <woot-switch :value="mode === 'EDIT'" @input="toggleMode" />
          {{ $t(`AUTOMATION.PREVIEW.MODE.EDIT`) }}
        </div>
      </label>
    </div>

    <div ref="previewContainer" class="preview-container">
      <div
        v-for="(message, index) in messages"
        :key="index"
        :class="{ 'message-container': true, sender: message.isSender }"
      >
        <div :class="messageClass(message.type, message.isSender)">
          <div v-if="message.content" class="message_content">
            {{ message.content }}
          </div>
          <div v-if="message.file" class="attachment">
            <bubble-image
              v-if="message.file.file_type.includes('image')"
              :url="message.file.file_url"
            />
            <audio
              v-else-if="message.file.file_type === 'audio'"
              controls
              class="skip-context-menu"
            >
              <source :src="`${message.file.file_url}?t=${Date.now()}`" />
            </audio>
            <bubble-video
              v-else-if="message.file.file_type === 'video'"
              :url="message.file.file_url"
            />
            <bubble-file v-else :url="message.file.file_url" />
          </div>
          <div v-if="message.contentAttribute" class="message-type">
            <div
              v-for="(template, templateIndex) in message.contentAttribute
                .payload"
              :key="templateIndex"
            >
              <bubble-quick-reply
                v-if="message.type === 'quick_reply'"
                :title="message.contentAttribute.title"
                :buttons="template.buttons || []"
                @on-click-button="clickButtonInMessageType"
              />
              <bubble-card-message
                v-if="
                  message.type === 'card_message' || message.type === 'carousel'
                "
                :title.sync="template.title"
                :subtitle.sync="template.subtitle"
                :cover-image="template.image_url"
                :buttons="template.buttons || []"
                @on-click-button="clickButtonInMessageType"
              />
            </div>
          </div>
          <div
            v-if="mode === 'EDIT' && message.category != 'text'"
            class="edit"
          >
            <woot-button
              v-tooltip.top="$t('AUTOMATION.FORM.EDIT')"
              variant="smooth"
              size="tiny"
              color-scheme="secondary"
              class-names="grey-btn"
              :is-loading="false"
              icon="edit"
              @click="onClickEdit(message)"
            />
          </div>
        </div>
      </div>
    </div>
  </woot-modal>
</template>

<script>
import { mapGetters } from 'vuex';
import BubbleImage from '../../../components/widgets/conversation/bubble/Image.vue';
import BubbleVideo from '../../../components/widgets/conversation/bubble/Video.vue';
import BubbleFile from '../../../components/widgets/conversation/bubble/File.vue';
import BubbleCardMessage from '../../../components/widgets/conversation/bubble/CardMessage.vue';
import BubbleQuickReply from '../../../components/widgets/conversation/bubble/QuickReply.vue';

const ACTION_NAME_MAP = {
  assign_agent: 'assign_agent',
  assign_team: 'assign_team',
  send_email_to_team: 'send_email_to_team',
  add_label: 'add_label',
  change_priority: 'send_activity',
  send_message_type: 'send_message_type',
  send_booking_link: 'send_info',
  send_email_transcript: 'send_activity',
  send_webhook_event: 'send_activity',
  mute_conversation: 'send_info',
  snooze_conversation: 'send_info',
  resolve_conversation: 'send_info',
  send_attachment: 'send_attachment',
  send_message: 'send_message',
  send_register_link: 'send_info',
  send_conversation_attributes_link: 'send_conversation_info',
};

export default {
  components: {
    BubbleImage,
    BubbleVideo,
    BubbleFile,
    BubbleCardMessage,
    BubbleQuickReply,
  },
  props: {
    automationRule: {
      type: Object,
      default: () => {},
    },
    show: {
      type: Boolean,
      default: false,
    },
    close: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      mode: 'SHOW',
      messages: [],
    };
  },
  computed: {
    ...mapGetters({
      automations: 'automations/getAutomations',
      messageTypes: 'messageTypes/getMessageTypes',
    }),
  },
  watch: {
    show: {
      handler() {
        if (!this.show) {
          this.messages = [];
          return;
        }
        this.generateAutomationMessage();
      },
      immediate: true,
    },
  },
  methods: {
    toggleMode() {
      if (this.mode === 'SHOW') this.mode = 'EDIT';
      else this.mode = 'SHOW';
    },
    messageClass(type, isSender = false) {
      return `message ${type} ${this.mode === 'EDIT' ? 'can-edit' : ''} ${
        isSender ? 'sender' : ''
      }`;
    },
    initAutomationPreview() {
      this.generateAutomationMessage();
    },
    generateAutomationMessage() {
      if (!this.automationRule.actions.length) return;
      this.automationRule.actions.forEach((action, index) => {
        const { action_name, action_params } = action;
        const type = ACTION_NAME_MAP[action_name];
        const category =
          action_name === 'send_message_type' ? 'messageType' : 'automation';

        const messagePayload = {
          index,
          type,
          content: this.generateContent(action_name, action_params),
          category,
          isSender: false,
          automationId: this.automationRule.id,
        };

        if (action_name === ACTION_NAME_MAP.send_attachment) {
          const file = this.automationRule.files.find(
            attachment => attachment.blob_id === action_params[0]
          );
          messagePayload.file = file;
        }

        if (action_name === ACTION_NAME_MAP.send_message_type) {
          const messageType = this.$store.getters[
            'messageTypes/getMessageType'
          ](action_params[0]);

          messagePayload.type = messageType.type;
          messagePayload.contentAttribute = messageType;
        }
        this.messages.push(messagePayload);
      });
    },
    generateContent(action_name = '', action_params = null) {
      if (
        ['send_attachment', 'send_message_type'].includes(
          ACTION_NAME_MAP[action_name]
        )
      )
        return null;

      switch (ACTION_NAME_MAP[action_name]) {
        case 'send_activity': {
          return `${this.$t(
            `AUTOMATION.PREVIEW.TYPE.${action_name.toUpperCase()}`
          )} ${action_params[0]}`;
        }
        case ACTION_NAME_MAP.assign_agent: {
          const agent = this.$store.getters['agents/getAgent'](
            action_params[0]
          );
          return `${this.$t(
            `AUTOMATION.PREVIEW.TYPE.${action_name.toUpperCase()}`
          )} ${agent?.name || ''}`;
        }
        case ACTION_NAME_MAP.assign_team: {
          const team = this.$store.getters['teams/getTeam'](action_params[0]);
          return `${this.$t(
            `AUTOMATION.PREVIEW.TYPE.${action_name.toUpperCase()}`
          )} ${team?.name || ''}`;
        }
        case ACTION_NAME_MAP.send_email_to_team: {
          const teamName = this.$store.getters['teams/getSelectedTeams'](
            action_params[0].team_ids
          );
          return `${this.$t(
            `AUTOMATION.PREVIEW.TYPE.${action_name.toUpperCase()}`
          )} ${teamName?.join(', ')}`;
        }
        case ACTION_NAME_MAP.add_label: {
          return `${this.$t(
            `AUTOMATION.PREVIEW.TYPE.${action_name.toUpperCase()}`
          )} ${action_params.join(', ')}`;
        }
        case ACTION_NAME_MAP.send_message ||
          ACTION_NAME_MAP.send_booking_link: {
          return action_params[0];
        }
        default: {
          return `${this.$t(
            `AUTOMATION.PREVIEW.TYPE.${action_name.toUpperCase()}`
          )}`;
        }
      }
    },
    clickButtonInMessageType(button) {
      const {
        action: { type },
      } = button;

      const value = button.action[type];
      if (type === 'url') {
        window.open(value, '_blank');
      } else {
        this.messages.push({
          index: this.messages.length,
          content: value,
          category: 'text',
          isSender: true,
          type: 'send_message',
        });

        const messageType = this.messageTypes.find(
          ({ keyword }) => keyword === value
        );

        if (messageType) {
          const messagePayload = {
            index: this.messages.length,
            content: null,
            category: 'messageType',
          };
          messagePayload.type = messageType.type;
          messagePayload.contentAttribute = messageType;
          this.messages.push(messagePayload);
        }

        const automation = this.findAutomation(value);

        if (automation) {
          this.generateAutomationMessage(automation);
        }
      }
      setTimeout(() => {
        this.scrollToBottom();
      }, 100);
    },
    findAutomation(keyword) {
      // const automationRule = this.automationRule.find(automation => {
      const { conditions } = this.automationRule;
      const automationRule = conditions.some(condition => {
        if (condition.attribute_key === 'content') {
          const value = condition.values[0];
          const { filter_operator: filterOperator } = condition;
          if (filterOperator === 'equal_to' && value === keyword) {
            return true;
          }

          if (filterOperator === 'not_equal_to' && value !== keyword) {
            return true;
          }

          if (filterOperator === 'contains' && value.includes(keyword)) {
            return true;
          }

          if (
            filterOperator === 'does_not_contain' &&
            !value.includes(keyword)
          ) {
            return true;
          }
        }

        return false;
      });

      return automationRule || null;
    },
    scrollToBottom() {
      const container = this.$refs.previewContainer;
      container.scrollTop = container.scrollHeight;
    },
    onClickEdit(message) {
      const { category, automationId, contentAttribute, index } = message;
      if (category === 'messageType') {
        this.$router.push({
          name: 'edit_message_type',
          params: { messageTypeId: contentAttribute.id },
        });
      } else {
        this.$router.push({
          name: 'edit_automation',
          params: { automationId },
          query: { automation_action_index: index },
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.mode-container {
  > label {
    width: max-content;
  }

  padding: 0 3.2rem 0 3.2rem;

  .mode-status {
    display: flex;
    gap: 5px;
    align-items: center;
  }
}

.preview-container {
  padding: 3.2rem 3.2rem 3.2rem;
}

.preview-container {
  height: 600px;
  overflow: auto;
  display: flex;

  flex-direction: column;
  gap: 8px;
  align-self: stretch;

  .message-container {
    &.sender {
      .avatar {
        justify-content: end;

        .image {
          order: 1;
          margin-right: 0;
          margin-left: 1rem;
        }
      }
    }

    .avatar {
      display: flex;
      align-items: center;
      margin: 1rem 0;

      .image {
        width: 30px;
        height: 30px;
        background: var(--w-500);
        border-radius: 100%;
        margin-right: 1rem;
      }
    }
  }

  .message {
    background: var(--w-500);
    border-radius: var(--space-one);
    border-bottom-left-radius: 0.4rem;
    border-top-left-radius: 0.4rem;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    /* 114.286% */
    width: max-content;

    &.sender {
      margin-left: auto;
      border-radius: var(--space-one);
      border-bottom-right-radius: 0.4rem;
      border-top-right-radius: 0.4rem;
    }

    .message_content {
      color: white;
      font-family: PlusJakarta, sans-serif;
    }

    &.send_activity,
    &.send_info,
    &.assign_agent,
    &.assign_team,
    &.send_email_to_team,
    &.add_label {
      width: 100%;
      background: unset;

      .message_content {
        border-radius: 8px;
        border: 0.5px solid rgba(132, 146, 166, 0.3);
        background: #eef3f7;
        color: var(--color-heading);
        padding: 10px;
        width: max-content;
        margin-left: auto;
        margin-right: auto;
        font-family: PlusJakarta, sans-serif;
      }
    }

    &.quick_reply,
    &.card_message,
    &.carousel {
      background: unset;
      width: 100%;
    }

    &.send_attachment {
      padding: var(--border-radius-medium);

      .image {
        max-width: 300px;
      }
    }

    &.send_message {
      padding: var(--border-radius-medium);
      max-width: 50%;
      white-space: pre-line;
    }

    &.can-edit {
      border-radius: 8px;
      background: white;
      display: flex;
      padding: 12px 10px;
      gap: 4px;
      align-self: stretch;
      width: 100%;

      &:hover {
        outline: 1.5px solid var(--w-500);
      }

      .message_content {
        margin-left: 0;
        color: var(--color-heading);
      }

      &.send_message,
      &.send_attachment {
        .message_content {
          padding: var(--space-one);
        }

        .message_content,
        .attachment {
          background: var(--w-500);
          border-radius: var(--space-one);
          color: white;
        }
      }

      .edit {
        margin-left: auto;
      }
    }

    .attachment {
      min-height: 0;
      height: 100%;

      > div {
        height: 100%;
      }
    }

    .message-type {
      display: flex;
      gap: var(--space-one);
      overflow: auto;
    }
  }
}
</style>
