/* global axios */
import ApiClient from './ApiClient';

export const buildMessageTypeParams = (
  per,
  page,
  keyword,
  title,
  without_inactive
) => {
  let params = `page=${page}`;
  if (per) {
    params = `${params}&per=${per}`;
  }

  if (keyword) {
    params = `${params}&keyword=${keyword}`;
  }

  if (title) {
    params = `${params}&title=${title}`;
  }

  if (without_inactive) {
    params = `${params}&without_inactive=true`;
  }

  return params;
};
class MessageTypeAPI extends ApiClient {
  constructor() {
    super('message_types', { accountScoped: true });
  }

  get(per, page = 1, keyword = '', title = '', without_inactive = false) {
    let requestURL = `${this.url}?${buildMessageTypeParams(
      per,
      page,
      keyword,
      title,
      without_inactive
    )}`;
    return axios.get(requestURL);
  }

  attachment(file) {
    return axios.post(`${this.url}/attach_file`, { attachment: file });
  }
}

export default new MessageTypeAPI();
