import { frontendURL } from '../../../../helper/URLHelper';

const bookings = accountId => ({
  parentNav: 'bookings',
  routes: [
    'booking_management',
    'service_management',
    'availability',
    'booking_calendar',
  ],
  menuItems: [
    {
      icon: 'calendar-empty',
      label: 'BOOKING.BOOKING_MANAGEMENT',
      key: 'booking_management',
      toState: frontendURL(`accounts/${accountId}/booking_management`),
      toolTip: 'Manage Booking',
      toStateName: 'booking_management',
      roles: ['administrator', 'supervisor', 'agent'],
    },
    {
      icon: 'calendar-empty',
      label: 'BOOKING.CALENDAR',
      key: 'booking_calendar',
      toState: frontendURL(`accounts/${accountId}/calendar`),
      toStateName: 'booking_calendar',
      roles: ['administrator', 'supervisor', 'agent'],
    },
    {
      icon: 'briefcase',
      label: 'BOOKING.SERVICE_MANAGEMENT',
      key: 'service_management',
      toState: frontendURL(`accounts/${accountId}/service_management`),
      toStateName: 'service_management',
      roles: ['administrator', 'supervisor', 'agent'],
    },
    {
      icon: 'calendar-clock',
      label: 'BOOKING.AVAILABILITY',
      key: 'availability',
      toState: frontendURL(`accounts/${accountId}/availability`),
      toStateName: 'availability',
      roles: ['administrator', 'supervisor', 'agent'],
    },
  ],
});

export default bookings;
