export const APP_BASE_URL = '';

export const MESSAGE_STATUS = {
  FAILED: 'failed',
  SUCCESS: 'success',
  PROGRESS: 'progress',
};

export const MESSAGE_TYPE = {
  INCOMING: 0,
  OUTGOING: 1,
  ACTIVITY: 2,
  TEMPLATE: 3,
};

export const ACTION_MESSAGES = [
  {
    name: 'card_message',
    label: 'Card Message',
  },
  {
    name: 'quick_reply',
    label: 'Quick Reply',
  },
  {
    name: 'carousel',
    label: 'Carousel',
  },
];

export const getActionTypeName = tagName => {
  const tag = ACTION_MESSAGES.find(
    actionMessage => actionMessage.name === tagName
  );

  if (!tag) {
    return tagName;
  }

  return tag.label;
};

export const WOOT_PREFIX = 'chatwoot-widget:';
