/* global axios */
import ApiClient from './ApiClient';

class AttributeAPI extends ApiClient {
  constructor() {
    super('custom_attribute_definitions', { accountScoped: true });
  }

  getAttributesByModel() {
    return axios.get(this.url);
  }

  updateOrder(attributes) {
    return axios.patch(`${this.url}/update_order`, { attributes });
  }
}

export default new AttributeAPI();
