import axios from 'axios';

export default {
  getEventTypes(email) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.VUE_APP_BOOKING_FRONTEND_URL}/api/eventTypes`, {
          params: { email },
        })
        .then(response => resolve(response))
        .catch(err => reject(err.response));
    });
  },
};
