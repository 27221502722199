<template>
  <div class="flex-1 overflow-auto p-4">
    <woot-button
      color-scheme="success"
      class-names="button--fixed-top"
      icon="add-circle"
      @click="openAddPopup()"
    >
      {{ $t('ATTRIBUTES_MGMT.HEADER_BTN_TXT') }}
    </woot-button>
    <custom-attribute @update-sort="isSort => (sort = isSort)" />
    <woot-modal :show.sync="showAddPopup" :on-close="hideAddPopup">
      <add-attribute :on-close="hideAddPopup" />
    </woot-modal>
  </div>
</template>

<script>
import toggleSortMixin from 'shared/mixins/toggleSortMixin';
import AddAttribute from './AddAttribute.vue';
import CustomAttribute from './CustomAttribute.vue';
export default {
  components: {
    AddAttribute,
    CustomAttribute,
  },
  mixins: [toggleSortMixin],
  data() {
    return {
      showAddPopup: false,
      sort: false,
    };
  },
  computed: {
    tableName() {
      return 'attributes';
    },
    successMessage() {
      return 'ATTRIBUTES_MGMT.EDIT.API.SUCCESS_MESSAGE';
    },
    errorMessage() {
      return 'ATTRIBUTES_MGMT.EDIT.API.ERROR_MESSAGE';
    },
  },
  methods: {
    openAddPopup() {
      this.showAddPopup = true;
    },
    hideAddPopup() {
      this.showAddPopup = false;
    },
  },
};
</script>
