<template>
  <div v-if="isLocation" class="location__card">
    <p>{{ getAddressTitle || title }}</p>
    <a
      class="button clear info location__link"
      :href="getMapLink"
      target="_blank"
    >
      {{ actionLabel }}
    </a>
  </div>
</template>

<script>
export default {
  props: {
    isLocation: {
      type: Boolean,
      default: true,
    },
    locationData: {
      type: Object,
      default: () => ({}),
    },
    actionLabel: {
      type: String,
      default: '',
    },
    url: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
  },
  computed: {
    getMapLink() {
      return this.locationData &&
        this.locationData.latitude &&
        this.locationData.longitude
        ? `https://maps.google.com/?q=${this.locationData.latitude},${this.locationData.longitude}`
        : this.url;
    },
    getAddressTitle() {
      return this.locationData.title || this.locationData.address;
    },
  },
};
</script>

<style lang="scss">
.location__card {
  display: flex;
  flex-direction: column;
  > p {
    margin-bottom: 0;
    text-align: center;
  }
  a.location__link {
    margin: auto;
  }
}
</style>
