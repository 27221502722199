<template>
  <div
    class="success-container flex flex-col bg-woot-50 min-h-screen w-full py-20 sm:px-6 lg:px-8 dark:bg-slate-900"
  >
    <div class="content">
      <img
        src="~dashboard/assets/images/thank_cuppo.svg"
        alt="Cuppo"
        class="cuppo"
      />
      <br />
      <h1 class="primary text-woot-500 dark:text-woot-50">
        <b class="primary text-woot-500 dark:text-woot-50">
          {{ $t('CONTACT_ATTRIBUTES.SUCCESS_PAGE.TITLE') }}
        </b>
      </h1>
      <br />
      <p class="text-slate-900 dark:text-woot-50">
        <b>
          {{ $t('CONTACT_ATTRIBUTES.SUCCESS_PAGE.DESCRIPTION') }}
        </b>
      </p>
      <br />
      <br />
      <p class="light-text">
        {{ $t('CONTACT_ATTRIBUTES.SUCCESS_PAGE.PLEAS_CLOSE_WINDOW') }}
      </p>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.success-container {
  display: flex;
  height: 100vh;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  padding: 10px 40px;

  .content {
    text-align: center;
    font-size: 14px;

    .primary {
      color: var(--w-500);
      font-size: 30px;
    }

    .light-text {
      color: var(--color-medium-gray);
    }
  }
}
</style>
