<template>
  <div class="form-wrapper">
    <form @submit.prevent="handleSubmit()">
      <div class="form-left-side">
        <div class="columns">
          <label :class="{ error: $v.messageType.title.$error }">
            {{ $t('MESSAGE_TYPE.FORM.TITLE.LABEL') }}
            <input
              v-model="messageType.title"
              type="text"
              :placeholder="$t('MESSAGE_TYPE.FORM.TITLE.PLACEHOLDER')"
              @input="$v.messageType.title.$touch"
            />
          </label>
          <label
            :class="{
              error:
                $v.messageType.keyword.$error || (errors && errors.keyword),
            }"
          >
            {{ $t('MESSAGE_TYPE.FORM.KEYWORD.LABEL') }}
            <input
              v-model="messageType.keyword"
              type="text"
              :maxlength="30"
              :placeholder="$t('MESSAGE_TYPE.FORM.KEYWORD.PLACEHOLDER')"
              @input="$v.messageType.keyword.$touch"
            />
            <span class="limitedText"
              >{{ messageType.keyword.length || 0 }}/30</span
            >
            <span v-if="errors && errors.keyword" class="error-message">
              {{ errors.keyword }}
            </span>
          </label>
          <label v-if="isQuickReplyType">
            {{ $t('MESSAGE_TYPE.FORM.INITIAL_MESSAGE.LABEL') }}
            <input
              v-model="messageType.initialMessage"
              type="text"
              :placeholder="$t('MESSAGE_TYPE.FORM.INITIAL_MESSAGE.PLACEHOLDER')"
              @input="$v.messageType.initialMessage.$touch"
            />
          </label>
          <label>
            {{ $t('MESSAGE_TYPE.FORM.ACTIVE.LABEL') }}
            <div class="message-status">
              <woot-switch
                :value="messageType.active"
                @input="toggleMessageType"
              />
              {{
                messageType.active
                  ? $t('MESSAGE_TYPE.FORM.ACTIVE.YES')
                  : $t('MESSAGE_TYPE.FORM.ACTIVE.NO')
              }}
            </div>
          </label>
          <div class="message-type-list">
            <div class="message-type-title">
              <span class="title">{{
                $t(
                  `MESSAGE_TYPE.FORM.MESSAGE_TYPES_LIST.${messageTypeUpperCase}.TITLE`
                )
              }}</span>
              <span>{{ messageTypeLength || 0 }}/10</span>
            </div>
            <div class="template-container">
              <div
                v-for="(template, index) in messageType.templates"
                :key="index"
                :class="`template-item ${getTemplateActiveClass(index)}`"
              >
                <h6
                  class="text-slate-900 dark:text-slate-100"
                  @click="selectedTemplate(index)"
                >
                  {{ templateTitle(template) }}
                </h6>
                <button
                  v-if="hasAtLeastOneTemplate"
                  type="button"
                  @click="removeTemplate(index)"
                >
                  <emoji-or-icon icon="dismiss" variant="clear" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="form-right-side">
        <div class="preview-container">
          <div v-for="(template, index) in messageType.templates" :key="index">
            <bubble-card-message
              v-if="isCardMessage"
              :title.sync="template.title"
              :subtitle.sync="template.subtitle"
              :cover-image="
                template.image_url ||
                '/assets/images/dashboard/message-types/card-bg.png'
              "
              :buttons="template.buttons || []"
              :validate-state="$v"
              mode-show="EDIT"
              :active="isTemplateActive(index)"
              @upload-image="setImageToTemplate"
              @select-card="selectedTemplate(index)"
            />
            <bubble-quick-reply
              v-if="isQuickReplyType"
              :title="template.title"
              :buttons="template.buttons || []"
              :active="isTemplateActive(index)"
              @on-click-button="() => selectedTemplate(index)"
            />
          </div>
          <div>
            <div
              v-if="!reachALimitTemplate"
              :class="`new_${messageTypeName}`"
              @click="addTemplate"
            >
              {{ $t(`MESSAGE_TYPE.FORM.NEW_${messageTypeUpperCase}`) }}
            </div>
          </div>
        </div>
        <div
          v-for="(button, index) in messageType.templates[selectedTemplateIndex]
            .buttons || []"
          :key="index"
          :class="`button-form for-${messageTypeName}`"
        >
          <div class="button-active">
            <label v-if="isCardMessage">
              <div class="message-status">
                <woot-switch
                  :value="!button.action.hide"
                  @input="toggleButtonActive(index)"
                />
                {{ $t(`MESSAGE_TYPE.FORM.BUTTON.STATUS_${index + 1}.LABEL`) }}
              </div>
            </label>
          </div>
          <div v-if="!button.action.hide" class="form__button-detail">
            <div class="flex flex-1 gap-1">
              <label v-if="isCardMessage" class="flex-1">
                {{ $t('MESSAGE_TYPE.FORM.BUTTON.BUTTON_TYPE') }}
                <select v-model="button.buttonType" disabled="true">
                  <option
                    v-for="(option, indexOption) in buttonTypeOptions"
                    :key="indexOption"
                    :value="option.value"
                  >
                    {{ option.label }}
                  </option>
                </select>
              </label>
              <label
                :class="{
                  active:
                    $v.messageType.templates.$each[selectedTemplateIndex]
                      .buttons.$each[index].label.$error,
                  'flex-1': true,
                }"
              >
                {{ $t('MESSAGE_TYPE.FORM.BUTTON.LABEL.TITLE') }}
                <input
                  v-model="button.label"
                  type="text"
                  :maxlength="15"
                  :placeholder="
                    $t('MESSAGE_TYPE.FORM.BUTTON.LABEL.PLACEHOLDER')
                  "
                />
                <span class="limitedText"
                  >{{ button.label.length || 0 }}/15</span
                >
              </label>
            </div>
            <div class="flex flex-1 gap-1">
              <label class="flex-1">
                {{ $t('MESSAGE_TYPE.FORM.BUTTON.ACTION_TYPE') }}
                <select v-model="button.action.type">
                  <option
                    v-for="(option, indexOption) in actionOptions"
                    :key="indexOption"
                    :value="option.value"
                  >
                    {{ $t(option.label) }}
                  </option>
                </select>
              </label>
              <div class="action_input_wrap flex-1">
                <label>
                  {{
                    $t(
                      `MESSAGE_TYPE.FORM.BUTTON.PAYLOAD.${button.action.type.toUpperCase()}.LABEL`
                    )
                  }}
                </label>

                <input
                  v-if="
                    ['url', 'postback', 'text'].includes(button.action.type)
                  "
                  v-model="button.action[button.action.type]"
                  type="text"
                  :maxlength="button.action.type === 'text' ? 50 : undefined"
                  :placeholder="
                    $t(
                      `MESSAGE_TYPE.FORM.BUTTON.PAYLOAD.${button.action.type.toUpperCase()}.PLACEHOLDER`
                    )
                  "
                />
                <message-type-select
                  v-else
                  :index="index"
                  :data-selected.sync="button.action[button.action.type]"
                  :options="getPayloadOption(button.action.type)"
                />
                <span v-if="button.action.type === 'text'" class="limitedText">
                  {{ button.action[button.action.type].length || 0 }}/50
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <button ref="form" class="hidden-button" type="submit" />
    </form>
    <woot-confirm-modal
      ref="confirmDialog"
      :title="$t('MESSAGE_TYPE.DELETE.TITLE')"
      :description="$t('MESSAGE_TYPE.DELETE.DESCRIPTION')"
    />
    <message-type-preview-modal
      :show="previewModal"
      :message-type="messageType"
      @on-close="() => $emit('on-close-preview-modal')"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import EmojiOrIcon from '../../../../../shared/components/EmojiOrIcon.vue';
import { required, url } from 'vuelidate/lib/validators';
import BubbleCardMessage from '../../../../../dashboard/components/widgets/conversation/bubble/CardMessage.vue';
import BubbleQuickReply from '../../../../../dashboard/components/widgets/conversation/bubble/QuickReply.vue';
import MessageTypePreviewModal from './MessageTypePreviewModal.vue';
import { actionDropDown, buttonTypeDropDown } from '../helpers';
import MessageTypeSelect from './MessageTypeSelect.vue';

export default {
  components: {
    EmojiOrIcon,
    BubbleCardMessage,
    BubbleQuickReply,
    MessageTypePreviewModal,
    MessageTypeSelect,
  },
  props: {
    messageTypeData: {
      type: Object,
      default: () => {},
    },
    previewModal: {
      type: Boolean,
      default: false,
    },
    onClosePreviewModal: {
      type: Function,
      default: () => {},
    },
    messageTypes: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      messageType: this.messageTypeData,
      selectedTemplateIndex: 0,
    };
  },
  computed: {
    ...mapGetters({
      errors: 'messageTypes/getErrors',
      eventTypes: 'eventTypes/getEventTypes',
    }),
    isQuickReplyType() {
      return this.messageTypeName === 'quick_reply';
    },
    isCardMessage() {
      return this.messageTypeName === 'card_message';
    },
    messageTypeLength() {
      return this.messageType.templates?.length || 0;
    },
    hasAtLeastOneTemplate() {
      return this.messageTypeLength > 1;
    },
    reachALimitTemplate() {
      return this.messageTypeLength >= 10;
    },
    latestMessageType() {
      return this.messageType.templates[this.messageTypeLength - 1];
    },
    activeMessageType() {
      return this.messageType.templates[this.selectedTemplateIndex];
    },
    buttonTypeOptions() {
      return buttonTypeDropDown();
    },
    actionOptions() {
      return actionDropDown(this.messageType.type);
    },
    messageTypesOptions() {
      return this.messageTypes
        .filter(
          messageType =>
            messageType.id !== Number(this.$route.params.messageTypeId)
        )
        .map(messageType => ({
          id: messageType.id,
          name: messageType.title,
          keyword: messageType.keyword,
          tag: messageType.type,
        }));
    },
    bookingLinkOptions() {
      return this.eventTypes.map(eventType => ({
        label: eventType.title,
        value: eventType.url_with_token,
      }));
    },
    messageTypeName() {
      return this.messageTypeData.type;
    },
    messageTypeUpperCase() {
      return this.messageTypeName?.toUpperCase() || '';
    },
    cardMessageTemplate() {
      return {
        id: this.latestMessageType.id + 1,
        imageUploaded: false,
        title: this.$t(
          `MESSAGE_TYPE.TEMPLATE_PREVIEW.${this.messageTypeUpperCase}.TITLE`
        ),
        image_url: '',
        type: 'card_message',
        buttons: [
          {
            buttonType: 'primary',
            hide: false,
            label: this.$t(
              'MESSAGE_TYPE.TEMPLATE_PREVIEW.CARD_MESSAGE.BUTTON_LABEL'
            ),
            action: {
              hide: false,
              type: 'text',
              postback: '',
              url: '',
              text: '',
            },
          },
          {
            buttonType: 'primary',
            hide: true,
            label: this.$t(
              'MESSAGE_TYPE.TEMPLATE_PREVIEW.CARD_MESSAGE.BUTTON_LABEL'
            ),
            action: {
              hide: true,
              type: 'text',
              postback: '',
              url: '',
              text: '',
            },
          },
        ],
      };
    },
    quickReplyTemplate() {
      return {
        id: this.latestMessageType.id + 1,
        title: this.$t(
          `MESSAGE_TYPE.TEMPLATE_PREVIEW.${this.messageTypeUpperCase}.TITLE`
        ),
        type: 'quick_reply',
        buttons: [
          {
            buttonType: 'primary',
            label: this.$t(
              `MESSAGE_TYPE.TEMPLATE_PREVIEW.${this.messageTypeUpperCase}.BUTTON_LABEL`
            ),
            action: {
              hide: false,
              type: 'text',
              postback: '',
              url: '',
              text: '',
            },
          },
        ],
      };
    },
    getMessageTypeParams() {
      if (this.isCardMessage && this.messageTypeLength > 1) {
        return 'carousel';
      }

      return this.messageTypeName;
    },
  },
  watch: {
    messageTypeData: {
      handler() {
        this.messageType = this.messageTypeData;
      },
      immediate: true,
    },
  },
  mounted() {
    // Todo Remove fetch agents and preview state
    this.$store.dispatch('agents/get');
    this.$store.dispatch('teams/get');
    this.$store.dispatch('automations/get');
    this.$store.dispatch('messageTypes/get', { per: 100 });
    this.$store.dispatch('eventTypes/get');
    this.$watch(
      '$v',
      () => {
        this.$emit('validation-state', this.$v);
      },
      { deep: true }
    );
  },
  methods: {
    getPayloadOption(type) {
      if (type === 'send_message_type') {
        return this.messageTypesOptions;
      }

      return this.bookingLinkOptions;
    },
    handleSubmit() {
      const messageTypePayload = {
        id: this.messageType.id || null,
        title: this.messageType.title,
        keyword: this.messageType.keyword,
        message_type: this.getMessageTypeParams,
        active: this.messageType.active,
        initial_message: this.messageType.initialMessage,
        content_attributes: this.getContentAttributes(
          this.messageType.templates
        ),
      };
      this.$emit('submit', messageTypePayload);
    },
    getContentAttributes(templates) {
      if (this.isCardMessage) {
        return templates.map(template => ({
          ...template,
          image_url: template.imageUploaded ? template.image_url : '',
          buttons: template.buttons.filter(button => !button.action.hide),
        }));
      }

      return templates.reduce(
        (acc, curr, index) => {
          const { buttons } = curr;
          acc[0].id = index + 1;
          acc[0].type = 'quick_reply';
          acc[0].title = this.messageType.initialMessage;
          acc[0].buttons.push(...buttons);
          return acc;
        },
        [{ buttons: [] }]
      );
    },
    getTemplateActiveClass(index) {
      if (this.isTemplateActive(index)) {
        return 'active';
      }
      return '';
    },
    isTemplateActive(index) {
      return this.selectedTemplateIndex === index;
    },
    toggleMessageType() {
      this.messageType.active = !this.messageType.active;
    },
    addTemplate() {
      if (this.reachALimitTemplate) return;

      let newTemplate = this.cardMessageTemplate;

      if (this.isQuickReplyType) {
        newTemplate = this.quickReplyTemplate;
      }

      this.messageType.templates.push(newTemplate);
      this.selectedTemplateIndex = newTemplate.id - 1;
    },
    async removeTemplate(index) {
      const ok = await this.$refs.confirmDialog.showConfirmation();
      if (ok) {
        this.messageType.templates.splice(index, 1);
        this.selectedTemplateIndex = 0;
      }
    },
    selectedTemplate(index) {
      this.selectedTemplateIndex = index;
    },
    async setImageToTemplate(image) {
      const index = this.messageType.templates.findIndex(
        template => template.id === this.activeMessageType.id
      );

      try {
        const { data } = await this.$store.dispatch(
          'messageTypes/uploadAttachment',
          image
        );

        this.messageType.templates[index].image_url = data.url;
        this.messageType.templates[index].blob_id = data.blob_id;
        this.messageType.templates[index].imageUploaded = true;
      } catch (error) {
        throw new Error(error);
      }
    },
    toggleButtonActive(buttonIndex) {
      if (buttonIndex === 0) return;

      this.activeMessageType.buttons[buttonIndex].action.hide =
        !this.activeMessageType.buttons[buttonIndex].action.hide;
    },
    templateTitle(template) {
      return this.isCardMessage ? template.title : template.buttons[0].label;
    },
  },
  validations() {
    const templatesValidates = {
      templates: {
        templateLength: value => {
          return !!value?.length || false;
        },
        $each: {
          title: {
            validateTitleField: value => {
              if (this.isCardMessage) {
                return required(value);
              }
              return true;
            },
          },
          buttons: {
            $each: {
              label: {
                required,
              },
              action: {
                type: {
                  validateTypeField: (value, parentVm) => {
                    if (!parentVm.hide) {
                      return required(value);
                    }
                    return true;
                  },
                },
                url: {
                  validateUrlField: (value, parentVm) => {
                    if (!parentVm.hide && parentVm.type === 'url') {
                      return required(value) && url(value);
                    }
                    return true; // Skip validation if type is not 'url'
                  },
                },
                postback: {
                  validatePostbackField: (value, parentVm) => {
                    if (!parentVm.hide && parentVm.type === 'postback') {
                      return required(value);
                    }
                    return true;
                  },
                },
                text: {
                  validateTextField: (value, parentVm) => {
                    if (!parentVm.hide && parentVm.type === 'text') {
                      return required(value);
                    }
                    return true;
                  },
                },
                send_message_type: {
                  validateSendMessageTypeField: (value, parentVm) => {
                    if (
                      !parentVm.hide &&
                      parentVm.type === 'send_message_type'
                    ) {
                      return required(value);
                    }
                    return true;
                  },
                },
              },
            },
          },
        },
      },
    };
    return {
      messageType: {
        title: {
          required,
        },
        keyword: {
          required,
        },
        initialMessage: {
          validateInitialMessageField: value => {
            if (this.isQuickReplyType) {
              return required(value);
            }

            return true;
          },
        },
        ...templatesValidates,
      },
    };
  },
};
</script>

<style lang="scss" scoped>
@import '../../../../assets/scss/variables.scss';
.form-wrapper {
  > form {
    display: grid;
    grid-template-columns: 30% 70%;
  }
  .hidden-button {
    display: none;
  }
  .form-left-side {
    padding: var(--space-small) var(--space-normal);
    overflow: scroll;
    height: calc(100vh - 5.6rem);
    min-height: calc(100vh - 5.6rem);
  }

  .form-right-side {
    overflow: scroll;
    height: calc(100vh - 5.6rem);
    min-height: calc(100vh - 5.6rem);
  }

  .message-type-list {
    > .message-type-title {
      .title {
        font-weight: bold;
      }
      display: flex;
      align-content: center;
      justify-content: space-between;
      margin-top: var(--space-normal);
      font-size: var(--space-snug);
    }
  }
}

.template-container {
  margin-top: var(--space-normal);
  display: flex;
  flex-direction: column;
  gap: 10px;
  overflow: scroll;
  .template-item {
    h6 {
      margin: 0;
      font-weight: normal;
      width: 100%;
      cursor: pointer;
    }
    button {
      cursor: pointer;
    }
    display: flex;
    width: 100%;
    padding: 8px;
    justify-content: space-between;
    align-items: center;
    border-radius: 5px;
    border: 1px solid rgba(31, 147, 255, 0.2);
    background: rgba(31, 147, 255, 0.1);
    &.active {
      border: 1px solid #1f93ff;
      background: rgba(31, 147, 255, 0.2);
      h6 {
        font-weight: bold;
      }
    }
  }
}

.message-status {
  display: flex;
  align-content: center;
  gap: 10px;
}

.preview-container {
  height: calc($card-height + 50px);
  padding: var(--space-normal);
  display: flex;
  gap: 10px;
  overflow: scroll;
  align-items: center;
}
.new_card_message {
  border-radius: 16px;
  border: 2px solid var(--s-200);
  background: white;
  min-height: $card-height;
  height: $card-height;
  min-width: $card-width;
  width: $card-width;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  // color: var(--w-500);

  cursor: pointer;
}

.new_quick_reply {
  border-radius: 20px;
  border: 1px solid var(--w-500);
  background: rgba(31, 147, 255, 0.2);
  padding: 8.5px 16px;
  color: var(--w-500);
  font-weight: bold;
  font-size: 14px;
  cursor: pointer;
  &:hover {
    background: var(--w-500);
    color: white;
  }
}

.button-form {
  display: flex;
  padding: var(--space-normal);

  &.for-quick_reply {
    .button-active {
      flex-basis: 0;
    }

    .form__button-detail {
      flex-basis: 100%;
    }
  }
  > div:first-child {
    flex-basis: 20%;
  }
  .form__button-detail {
    flex-basis: 80%;
    display: flex;
    flex-direction: column;
    .row > label {
      flex: 1 1;
    }

    .row {
      display: flex;
      gap: var(--space-normal);
      flex-wrap: nowrap;
    }
  }
}

.error {
  border-color: var(--r-400);
}
.error-message {
  color: var(--r-400);
  display: block;
  font-size: 1.4rem;
  font-size: var(--font-size-small);
  font-weight: 400;
  margin-bottom: 1rem;
  margin-top: -1.6rem;
  width: 100%;
}

.action_input_wrap {
  flex: 1 1;
}

.limitedText {
  text-align: right;
  display: flex;
  justify-content: flex-end;
  font-size: 0.7rem;
  font-family: PlusJakarta, sans-serif;
}

input {
  margin-bottom: 0px;
}
</style>
