import { frontendURL } from '../../../../helper/URLHelper';

const messagetypes = accountId => ({
  parentNav: 'message_types',
  routes: ['message_types'],
  menuItems: [
    {
      icon: 'calendar-empty',
      label: 'MESSAGE_TYPE.MESSAGE_TYPE_MANAGEMENT',
      key: 'message_types',
      toState: frontendURL(`accounts/${accountId}/message_types`),
      toolTip: 'Manage Message Types',
      toStateName: 'message_types',
      roles: ['administrator', 'supervisor', 'agent'],
    },
    {
      icon: 'automation',
      label: 'AUTOMATION',
      key: 'message_types',
      toState: frontendURL(
        `accounts/${accountId}/message_types/automation/list`
      ),
      toolTip: 'automation',
      toStateName: 'automation_list',
      roles: ['administrator', 'supervisor', 'agent'],
    },
  ],
});

export default messagetypes;
