<template>
  <div class="quick-reply">
    <div class="quick-reply-buttons">
      <button
        v-for="(button, index) in buttons"
        :key="index"
        :class="{ active, 'quick-reply__button': true }"
        type="button"
        @click="handleClick(button)"
      >
        {{ button.label }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: '',
    },
    buttons: {
      type: Array,
      default: () => [],
    },
    active: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    handleClick(button) {
      this.$emit('on-click-button', button);
    },
  },
};
</script>

<style lang="scss" scoped>
@mixin active-button {
  background: var(--w-500);
  color: white;
}
.quick-reply {
  .quick-reply-buttons {
    display: flex;
    justify-content: end;
    align-items: center;
    gap: 8px;
    .quick-reply__button {
      border-radius: 20px;
      border: 1px solid var(--w-500);
      background: rgba(31, 147, 255, 0.2);
      padding: 12px 16px;
      color: var(--w-500);
      font-weight: bold;
      font-size: 14px;
      cursor: pointer;
      &.active {
        @include active-button;
      }
      &:hover {
        @include active-button;
      }
    }
  }
}
</style>
