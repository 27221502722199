<template>
  <div
    class="flex flex-1 h-full justify-between flex-col m-0 bg-slate-25 dark:bg-slate-900"
  >
    <settings-header
      button-route="new"
      :header-title="$t('AUTOMATION.LIST.TITLE')"
      :show-new-button="false"
      :show-sidemenu-icon="false"
      icon="automation"
    >
      <div class="cta-container">
        <woot-button
          v-if="isFeatureEnable(messageTypeFeatureFlag)"
          color-scheme="primary"
          icon="message-type"
          variant="clear"
          @click="() => (showListMessageType = true)"
        >
          {{ $t('AUTOMATION.LIST_MESSAGE_TYPE') }}
        </woot-button>
        <woot-button
          color-scheme="success"
          icon="add-circle"
          @click="openAddPopup()"
        >
          {{ $t('AUTOMATION.HEADER_BTN_TXT') }}
        </woot-button>
      </div>
    </settings-header>
    <div class="flex-1 overflow-auto p-4">
      <div class="row">
        <div class="small-9 columns with-right-space">
          <p
            v-if="!uiFlags.isFetching && !records.length"
            class="no-items-error-message"
          >
            {{ $t('AUTOMATION.LIST.404') }}
          </p>
          <woot-loading-state
            v-if="uiFlags.isFetching"
            :message="$t('AUTOMATION.LOADING')"
          />
          <table
            v-if="!uiFlags.isFetching && records.length"
            class="woot-table automation-table"
          >
            <thead>
              <th
                v-for="thHeader in $t('AUTOMATION.LIST.TABLE_HEADER')"
                :key="thHeader"
                :class="thHeader"
              >
                {{ thHeader }}
              </th>
            </thead>
            <tbody>
              <tr v-for="(automation, index) in records" :key="index">
                <td>{{ automation.name }}</td>
                <td style="text-align: center">
                  <woot-switch
                    :value="automation.active"
                    size="small"
                    @input="toggleAutomation(automation, automation.active)"
                  />
                </td>
                <td>
                  <div class="action-label-container">
                    <div
                      v-for="(action, actionIndex) in groupActions(
                        automation.actions
                      )"
                      :key="actionIndex"
                    >
                      <div :class="action" />
                    </div>
                  </div>
                </td>
                <td>
                  <div class="action-in-use-container">
                    <div
                      v-for="(title, titleIndex) in getMessageTypesTitle(
                        automation.message_types
                      )"
                      :key="titleIndex"
                      class="message-use"
                    >
                      <div>{{ title }}</div>
                    </div>
                  </div>
                </td>
                <td>
                  <div class="action-in-message-use-container">
                    <div
                      v-for="(
                        listTitle, titleIndex
                      ) in getContentAttributesLabel(automation.message_types)"
                      :key="titleIndex"
                      class="message-use"
                    >
                      <div class="messagediv">
                        {{ listTitle[0] }}
                      </div>
                      <div v-if="listTitle.length > 1" class="tooltip1">
                        {{ '+' + (listTitle.length - 1) }}

                        <div class="tooltiptext">
                          <div class="headertooltiptext">
                            {{
                              '"' +
                              getMessageTypesTitleTooltip(
                                automation.message_types,
                                titleIndex
                              ) +
                              '"'
                            }}
                          </div>
                          <span>{{ $t('AUTOMATION.LIST.MORE.DETAIL') }}</span>

                          <div
                            v-for="(l, i) in getMessageTypesList(
                              automation.message_types,
                              titleIndex
                            )"
                            :key="i"
                            class="message-use"
                          >
                            <div>
                              {{ l }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </td>
                <td>{{ readableTime(automation.created_on) }}</td>
                <td class="button-wrapper">
                  <woot-button
                    color-scheme="info"
                    size="tiny"
                    @click="showPreviewAutomation(automation)"
                  >
                    {{ $t('AUTOMATION.PREVIEW.TITLE') }}
                  </woot-button>
                  <woot-button
                    variant="smooth"
                    size="tiny"
                    color-scheme="secondary"
                    class-names="grey-btn"
                    :is-loading="loading[automation.id]"
                    icon="edit"
                    @click="
                      () =>
                        $router.push({
                          name: 'edit_automation',
                          params: { automationId: automation.id },
                        })
                    "
                  />
                  <woot-button
                    variant="smooth"
                    color-scheme="alert"
                    size="tiny"
                    icon="dismiss-circle"
                    class-names="grey-btn"
                    :is-loading="loading[automation.id]"
                    @click="openDeletePopup(automation, index)"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="small-3 columns">
          <span v-dompurify-html="$t('AUTOMATION.SIDEBAR_TXT')" />
          <div class="subtext bg-slate-25 dark:bg-slate-900">
            <span>{{ $t('AUTOMATION.ACTION_LABEL') }}</span>
            <div class="cyclewarp">
              <div class="assign_agent" />
              {{ $t('AUTOMATION.ASSIGN_AGENT') }}
            </div>
            <div class="cyclewarp">
              <div class="assign_team" />
              {{ $t('AUTOMATION.ASSIGN_TEAM') }}
            </div>
            <div class="cyclewarp">
              <div class="add_label" />
              {{ $t('AUTOMATION.ADD_LABEL') }}
            </div>
            <div class="cyclewarp">
              <div class="send_booking_link" />
              {{ $t('AUTOMATION.SEND_BOOKING_LINK') }}
            </div>
            <div class="cyclewarp">
              <div class="resolve_conversation" />
              {{ $t('AUTOMATION.RESOLVE_CONVERSATION') }}
            </div>
            <div class="cyclewarp">
              <div class="others" />
              {{ $t('AUTOMATION.OTHERS') }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <woot-delete-modal
      :show.sync="showDeleteConfirmationPopup"
      :on-close="closeDeletePopup"
      :on-confirm="confirmDeletion"
      :title="$t('LABEL_MGMT.DELETE.CONFIRM.TITLE')"
      :message="$t('AUTOMATION.DELETE.CONFIRM.MESSAGE')"
      :message-value="deleteMessage"
      :confirm-text="deleteConfirmText"
      :reject-text="deleteRejectText"
    />

    <woot-modal
      :show.sync="showEditPopup"
      size="medium"
      :on-close="hideEditPopup"
    >
      <edit-automation-rule
        v-if="showEditPopup"
        :on-close="hideEditPopup"
        :selected-response="selectedResponse"
        @saveAutomation="submitAutomation"
      />
    </woot-modal>
    <woot-confirm-modal
      ref="confirmDialog"
      :title="toggleModalTitle"
      :description="toggleModalDescription"
    />
    <automation-preview
      :show.sync="showPreview"
      :automation-rule="selectedResponse"
      @close="() => (showPreview = false)"
    />
    <create-automation-modal
      :show.sync="createModal"
      :message-type-feature-enable="isFeatureEnable(messageTypeFeatureFlag)"
      @close="() => (createModal = false)"
    />
    <woot-modal
      :show="showListMessageType"
      :on-close="() => (showListMessageType = false)"
      size="modal-big"
    >
      <woot-modal-header :header-title="$t('MESSAGE_TYPE.LIST.TITLE')" />
      <message-types-table />
    </woot-modal>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import EditAutomationRule from './EditAutomationRule.vue';
import alertMixin from 'shared/mixins/alertMixin';
import timeMixin from 'dashboard/mixins/time';
import AutomationPreview from './AutomationPreview.vue';
import CreateAutomationModal from './CreateAutomationModal.vue';
import SettingsHeader from '../settings/SettingsHeader.vue';
import MessageTypesTable from '../messagetypes/components/MessageTypesTable.vue';
import { FEATURE_FLAGS } from '../../../featureFlags';

export default {
  components: {
    EditAutomationRule,
    AutomationPreview,
    CreateAutomationModal,
    SettingsHeader,
    MessageTypesTable,
  },
  mixins: [alertMixin, timeMixin],
  data() {
    return {
      hoverShowMore: true,
      loading: {},
      showAddPopup: false,
      showEditPopup: false,
      showDeleteConfirmationPopup: false,
      selectedResponse: {},
      toggleModalTitle: this.$t('AUTOMATION.TOGGLE.ACTIVATION_TITLE'),
      toggleModalDescription: this.$t(
        'AUTOMATION.TOGGLE.ACTIVATION_DESCRIPTION'
      ),
      showPreview: false,
      automationSelected: null,
      createModal: false,
      showListMessageType: false,
    };
  },
  computed: {
    ...mapGetters({
      records: ['automations/getAutomations'],
      uiFlags: 'automations/getUIFlags',
      isFeatureEnabledonAccount: 'accounts/isFeatureEnabledonAccount',
      accountId: 'getCurrentAccountId',
    }),
    // Delete Modal
    deleteConfirmText() {
      return `${this.$t('AUTOMATION.DELETE.CONFIRM.YES')} ${
        this.selectedResponse.name
      }`;
    },
    deleteRejectText() {
      return `${this.$t('AUTOMATION.DELETE.CONFIRM.NO')} ${
        this.selectedResponse.name
      }`;
    },
    deleteMessage() {
      return ` ${this.selectedResponse.name}?`;
    },
    messageTypeFeatureFlag() {
      return FEATURE_FLAGS.MESSAGE_TYPES;
    },
  },
  mounted() {
    this.$store.dispatch('inboxes/get');
    this.$store.dispatch('agents/get');
    this.$store.dispatch('contacts/get');
    this.$store.dispatch('teams/get');
    this.$store.dispatch('labels/get');
    this.$store.dispatch('campaigns/get');
    this.$store.dispatch('automations/get');
    this.$store.dispatch('messageTypes/get', {
      without_inactive: true,
      per: 100,
    });
    this.$store.dispatch('eventTypes/get');
  },
  methods: {
    getMessageTypesTitle(messageTypes) {
      return messageTypes.map(messageType => messageType.title);
    },
    getContentAttributesLabel(messageTypes) {
      const mapData = messageTypes.map(messageType => {
        if (messageType.type === 'quick_reply') {
          return messageType.content_attributes[0].buttons.map(
            button => button.label
          );
        }
        return messageType.content_attributes.map(content => content.title);
      });
      return mapData;
    },
    getMessageTypesTitleTooltip(messageTypes, index) {
      const mapData = messageTypes.map(messageType => messageType.title);
      return mapData[index];
    },
    getMessageTypesList(messageTypes, index) {
      const mapData = messageTypes.map(messageType => {
        if (messageType.type === 'quick_reply') {
          return messageType.content_attributes[0].buttons.map(
            button => button.label
          );
        }
        return messageType.content_attributes.map(content => content.title);
      });
      return mapData[index];
    },
    openAddPopup() {
      this.createModal = true;
    },
    hideAddPopup() {
      this.createModal = false;
    },
    openEditPopup(response) {
      this.selectedResponse = response;
      this.showEditPopup = true;
    },
    hideEditPopup() {
      this.showEditPopup = false;
    },
    openDeletePopup(response) {
      this.showDeleteConfirmationPopup = true;
      this.selectedResponse = response;
    },
    closeDeletePopup() {
      this.showDeleteConfirmationPopup = false;
    },
    confirmDeletion() {
      this.loading[this.selectedResponse.id] = true;
      this.closeDeletePopup();
      this.deleteAutomation(this.selectedResponse.id);
    },
    async deleteAutomation(id) {
      try {
        await this.$store.dispatch('automations/delete', id);
        this.showAlert(this.$t('AUTOMATION.DELETE.API.SUCCESS_MESSAGE'));
        this.loading[this.selectedResponse.id] = false;
      } catch (error) {
        this.showAlert(this.$t('AUTOMATION.DELETE.API.ERROR_MESSAGE'));
      }
    },
    async cloneAutomation(id) {
      try {
        await this.$store.dispatch('automations/clone', id);
        this.showAlert(this.$t('AUTOMATION.CLONE.API.SUCCESS_MESSAGE'));
        this.$store.dispatch('automations/get');
        this.loading[this.selectedResponse.id] = false;
      } catch (error) {
        this.showAlert(this.$t('AUTOMATION.CLONE.API.ERROR_MESSAGE'));
      }
    },
    async submitAutomation(payload, mode) {
      try {
        const action =
          mode === 'edit' ? 'automations/update' : 'automations/create';
        const successMessage =
          mode === 'edit'
            ? this.$t('AUTOMATION.EDIT.API.SUCCESS_MESSAGE')
            : this.$t('AUTOMATION.ADD.API.SUCCESS_MESSAGE');
        await this.$store.dispatch(action, payload);
        this.showAlert(successMessage);
        this.hideAddPopup();
        this.hideEditPopup();
      } catch (error) {
        const errorMessage =
          mode === 'edit'
            ? this.$t('AUTOMATION.EDIT.API.ERROR_MESSAGE')
            : this.$t('AUTOMATION.ADD.API.ERROR_MESSAGE');
        this.showAlert(errorMessage);
      }
    },
    async toggleAutomation(automation, status) {
      try {
        this.toggleModalTitle = status
          ? this.$t('AUTOMATION.TOGGLE.DEACTIVATION_TITLE')
          : this.$t('AUTOMATION.TOGGLE.ACTIVATION_TITLE');
        this.toggleModalDescription = status
          ? this.$t('AUTOMATION.TOGGLE.DEACTIVATION_DESCRIPTION', {
              automationName: automation.name,
            })
          : this.$t('AUTOMATION.TOGGLE.ACTIVATION_DESCRIPTION', {
              automationName: automation.name,
            });
        // Check if user confirms to proceed
        const ok = await this.$refs.confirmDialog.showConfirmation();
        if (ok) {
          await this.$store.dispatch('automations/update', {
            id: automation.id,
            active: !status,
          });
          const message = status
            ? this.$t('AUTOMATION.TOGGLE.DEACTIVATION_SUCCESFUL')
            : this.$t('AUTOMATION.TOGGLE.ACTIVATION_SUCCESFUL');
          this.showAlert(message);
        }
      } catch (error) {
        this.showAlert(this.$t('AUTOMATION.EDIT.API.ERROR_MESSAGE'));
      }
    },
    readableTime(date) {
      return this.messageStamp(new Date(date), 'LLL d, h:mm a');
    },
    showPreviewAutomation(automation) {
      this.selectedResponse = automation;
      this.showPreview = true;
    },
    groupActions(actions) {
      const originalArray = [
        'assign_agent',
        'add_label',
        'assign_team',
        'send_booking_link',
        'others',
      ];
      const mergedArray = actions.map(({ action_name }) => {
        if (!originalArray.includes(action_name)) {
          return 'others';
        }

        return action_name;
      });

      // const sortedArray = mergedArray.sort((a, b) => {
      //   return originalArray.indexOf(a) - originalArray.indexOf(b);
      // });

      return mergedArray;
    },
    isFeatureEnable(featureName) {
      return this.isFeatureEnabledonAccount(this.accountId, featureName);
    },
  },
};
</script>

<style scoped lang="scss">
.automation__status-checkbox {
  margin: 0;
}
.toggle-button {
  background-color: var(--s-200);
  position: relative;
  display: inline-flex;
  height: 19px;
  width: 34px;
  border: 2px solid transparent;
  border-radius: var(--border-radius-large);
  cursor: pointer;
  transition-property: background-color;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 200ms;
  flex-shrink: 0;
}

.toggle-button.active {
  background-color: var(--w-500);
}

.toggle-button span {
  --space-one-point-five: 1.5rem;
  height: var(--space-one-point-five);
  width: var(--space-one-point-five);
  display: inline-block;
  background-color: var(--white);
  box-shadow:
    rgb(255, 255, 255) 0px 0px 0px 0px,
    rgba(59, 130, 246, 0.5) 0px 0px 0px 0px,
    rgba(0, 0, 0, 0.1) 0px 1px 3px 0px,
    rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
  transform: translate(0, 0);
  border-radius: 100%;
  transition-property: transform;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 200ms;
}
.toggle-button span.active {
  transform: translate(var(--space-one-point-five), var(--space-zero));
}
.subtext {
  display: flex;
  flex-direction: column;
  gap: 8px;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
}
.cyclewarp {
  font-family: PlusJakarta;
  display: flex;
  align-items: center;
  gap: 8px;
}

.action-label-container {
  display: flex;
  gap: 0.5rem;
}
.assign_agent {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #a650fd;
}
.assign_team {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #09f;
}
.add_label {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #29cc6a;
}
.send_booking_link {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #fc6a6a;
}
.resolve_conversation {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #fcd36a;
}
.others {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #c9d7e3;
}

.cta-container {
  position: absolute;
  right: var(--space-one);
}

.action-in-use-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  .message-use {
    display: flex;
    gap: 0.5rem;

    > div {
      border-radius: 20px;
      background: rgba(0, 0, 0, 0.05);
      padding: 0px 8px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.action-in-message-use-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  .message-use {
    display: flex;
    gap: 0.5rem;

    .messagediv {
      border-radius: 20px;
      background: rgba(0, 0, 0, 0.05);
      padding: 0px 8px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      min-width: 0;
    }
  }
}

.automation-table {
  table-layout: fixed;
  .Active {
    width: 70px;
  }
}

.hovershowmore-popup {
  width: 312px !important;
  height: auto;
}

.tooltip1 {
  position: relative;
  display: inline-block;
  color: #3c4858;
  border-radius: 20px;
  background: rgba(0, 0, 0, 0.05);
  padding: 0px 8px;
  width: 49px;
}

.tooltip1 .tooltiptext {
  visibility: hidden;
  width: 312px;
  color: #1f2d3d;
  text-align: center;
  padding: 5px 0;
  display: inline-flex;
  padding: 16px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 16px;
  border-radius: 6px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  background: #fff;
  box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.1);

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 50%;
  margin-left: -60px;
}

.tooltip1:hover .tooltiptext {
  visibility: visible;
}

.headertooltiptext {
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
</style>
