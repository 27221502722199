export const FEATURE_FLAGS = {
  AGENT_BOTS: 'agent_bots',
  AGENT_MANAGEMENT: 'agent_management',
  AUTO_RESOLVE_CONVERSATIONS: 'auto_resolve_conversations',
  AUTOMATIONS: 'automations',
  CAMPAIGNS: 'campaigns',
  CANNED_RESPONSES: 'canned_responses',
  CRM: 'crm',
  CUSTOM_ATTRIBUTES: 'custom_attributes',
  INBOX_MANAGEMENT: 'inbox_management',
  INTEGRATIONS: 'integrations',
  LABELS: 'labels',
  MACROS: 'macros',
  HELP_CENTER: 'help_center',
  REPORTS: 'reports',
  TEAM_MANAGEMENT: 'team_management',
  VOICE_RECORDER: 'voice_recorder',
  AUDIT_LOGS: 'audit_logs',
  MESSAGE_REPLY_TO: 'message_reply_to',
  BOOKING: 'booking',
  APPLICATION: 'application',
  MESSAGE_TYPES: 'message_types',
  REGISTER: 'register',
  PRIVATE_ADMIN_NOTE: 'private_admin_note',
  AGENTS_CONVERSATION_ASSIGNED_ONLY: 'agents_conversation_assigned_only',
  CONVERSATION_ATTRIBUTE_FORM: 'conversation_attribute_form',
};
