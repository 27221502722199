<template>
  <dashboard-app-frame :config="getConfig" :is-visible="true" :position="1" />
</template>

<script>
import { mapGetters } from 'vuex';
import DashboardAppFrame from '../../../components/widgets/DashboardApp/Frame.vue';

export default {
  components: { DashboardAppFrame },
  computed: {
    ...mapGetters({
      getAccount: 'accounts/getAccount',
      currentAccountId: 'getCurrentAccountId',
    }),
    getConfig() {
      const { support_email } = this.getAccount(this.currentAccountId);
      const username = support_email?.split('@')[0].toLowerCase();
      return [
        {
          type: 'frame',
          url: `${process.env.VUE_APP_BOOKING_FRONTEND_URL}/${username}/bookings-history/today`,
        },
      ];
    },
  },
};
</script>
