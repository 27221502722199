import { frontendURL } from '../../../helper/URLHelper';
import MessageTypeEditor from './pages/MessageTypeEditor';

export default {
  routes: [
    {
      path: frontendURL('accounts/:accountId/message_types/new'),
      name: 'new_message_types',
      roles: ['administrator', 'supervisor', 'agent'],
      component: MessageTypeEditor,
    },
    {
      path: frontendURL(
        'accounts/:accountId/message_types/:messageTypeId/edit'
      ),
      name: 'edit_message_type',
      roles: ['administrator', 'supervisor', 'agent'],
      component: MessageTypeEditor,
      props: route => {
        return { messageTypeId: route.params.messageTypeId };
      },
    },
  ],
};
