/* global axios */
import ApiClient from './ApiClient';

class CannedResponse extends ApiClient {
  constructor() {
    super('canned_responses', { accountScoped: true });
  }

  get({ searchKey }) {
    const url = searchKey ? `${this.url}?search=${searchKey}` : this.url;
    return axios.get(url);
  }

  updateOrder(cannedResponses) {
    return axios.patch(`${this.url}/update_order`, {
      canned_responses: cannedResponses,
    });
  }
}

export default new CannedResponse();
