<template>
  <div :class="cardMessageClass" @click="selectCard">
    <div
      v-if="coverImage"
      :style="{ backgroundImage: `url(${coverImage})` }"
      class="card-image"
    >
      <fluent-icon v-if="isEditorMode && active" icon="camera" />
      <button
        v-if="isEditorMode && active"
        type="button"
        @click="clickUploadImage"
      >
        {{ $t('MESSAGE_TYPE.TEMPLATE_PREVIEW.CARD_MESSAGE.UPLOAD_IMAGE') }}
      </button>
      <input
        v-if="isEditorMode"
        ref="uploadImage"
        type="file"
        accept="image/png, image/jpeg, image/jpg"
        :style="{ display: 'none' }"
        @change="handleImage"
      />
    </div>
    <div class="card-content">
      <h6 v-if="!isEditorMode || !active" class="text-left">{{ title }}</h6>
      <input
        v-else
        :value="title"
        type="text"
        :maxlength="20"
        @input="$emit('update:title', $event.target.value)"
      />
      <div v-if="showTextLimit" class="textlimit">
        {{ title.length || 0 }}/20
      </div>
      <p v-if="!isEditorMode || !active" class="text-left desc">
        {{ subtitle || '' }}
      </p>
      <textarea
        v-else
        :value="subtitle"
        class="description"
        :maxlength="maxtextlimit"
        @input="$emit('update:subtitle', $event.target.value)"
      />
      <div v-if="showTextLimit" class="textlimit">
        {{ subtitle.length + '/' + maxtextlimit }}
      </div>
    </div>
    <div class="card-footer">
      <woot-button
        v-for="(button, index) in buttons"
        :key="index"
        :is-expanded="true"
        :style="{
          display: button.action.hide ? 'none' : '',
          'text-align': 'center',
        }"
        size="expanded"
        :color-scheme="button.buttonType"
        type="button"
        @click="clickCardButton(button)"
      >
        {{ button.label }}
      </woot-button>
    </div>
    <woot-modal
      v-if="!disabledCropImage"
      :show="showCropImageModal"
      :on-close="closeCropModal"
      size="medium"
    >
      <div class="column content-box">
        <woot-crop-image
          ref="cropperComponent"
          :img-src="imageSelected"
          @on-crop="onCrop"
        />
      </div>
    </woot-modal>
  </div>
</template>

<script>
import WootCropImage from '../../../ui/WootCropImage.vue';
import WootButton from '../../../ui/WootButton.vue';

export default {
  components: {
    WootCropImage,
    WootButton,
  },
  props: {
    title: {
      type: String,
      default: '',
      require: true,
    },
    subtitle: {
      type: String,
      default: '',
    },
    buttons: {
      type: Array,
      default: () => [],
    },
    coverImage: {
      type: String,
      default: null,
    },
    modeShow: {
      type: String,
      default: 'SHOW',
    },
    active: {
      type: Boolean,
      default: false,
    },
    disabledCropImage: {
      type: Boolean,
      default: false,
    },
    showTextLimit: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      mode: this.modeShow,
      showCropImageModal: false,
      imageSelected: '',
      maxtextlimit: 60,
    };
  },
  computed: {
    cardMessageClass() {
      return `card-message ${this.coverImage ? 'with-image' : ''} ${
        this.active ? 'active' : ''
      }`;
    },
    isEditorMode() {
      return this.mode === 'EDIT';
    },
  },
  methods: {
    clickCardButton(button) {
      this.$emit('on-click-button', button);
    },
    clickUploadImage() {
      this.$refs.uploadImage.click();
    },
    handleImage(e) {
      const file = e.target.files[0];
      if (file) {
        this.processImage(file);
      }
    },
    processImage(file) {
      const reader = new FileReader();
      reader.onload = event => {
        this.imageSelected = event.target.result;
        this.showCropImageModal = true;
      };
      reader.readAsDataURL(file);
    },
    onCrop(imageBase64) {
      this.showCropImageModal = false;
      this.$emit('upload-image', imageBase64);
    },
    selectCard() {
      this.$emit('select-card');
    },
    closeCropModal() {
      this.showCropImageModal = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../../../assets/scss/variables.scss';
.card-message {
  border-radius: 16px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: #fff;
  min-height: 100px;
  min-width: $card-width;
  width: $card-width;
  display: flex;
  flex-direction: column;
  &.with-image {
    min-height: $card-height;
    // height: $card-height;
  }
  .card-image {
    min-height: 150px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    svg {
      color: var(--w-500);
    }
    > button {
      color: var(--w-500);
    }
  }
  .card-content {
    padding: 0.6rem 0.6rem;
    .description {
      margin-bottom: 0px;
    }
    .textlimit {
      display: flex;
      justify-content: flex-end;
      font-size: 0.7rem;
      font-family: PlusJakarta, sans-serif;
    }
  }
  .card-footer {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 0.6rem 0.6rem;
    margin-top: auto;
  }

  &.active {
    border: 2px solid var(--w-500);
  }
  .desc {
    font-family: PlusJakarta, sans-serif;
  }
  input {
    margin-bottom: 0px;
  }
}
</style>
