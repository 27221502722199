<template>
  <div class="flex-1 overflow-auto p-4">
    <woot-button
      color-scheme="success"
      class-names="button--fixed-top"
      icon="add-circle"
      @click="openAddPopup()"
    >
      {{ $t('CANNED_MGMT.HEADER_BTN_TXT') }}
    </woot-button>

    <!-- List Canned Response -->
    <div class="flex flex-row gap-4">
      <div class="w-[60%]">
        <sort-section
          :sort="sort"
          @toggle-sort="toggleSort"
          @update-order="updateOrder"
        />
        <p
          v-if="!uiFlags.fetchingList && !records.length"
          class="flex h-full items-center flex-col justify-center"
        >
          {{ $t('CANNED_MGMT.LIST.404') }}
        </p>
        <woot-loading-state
          v-if="uiFlags.fetchingList"
          :message="$t('CANNED_MGMT.LOADING')"
        />

        <table
          v-if="!uiFlags.fetchingList && records.length"
          class="woot-table"
        >
          <thead>
            <th v-if="sort" />
            <!-- Header -->
            <th
              v-for="thHeader in $t('CANNED_MGMT.LIST.TABLE_HEADER')"
              :key="thHeader"
            >
              {{ thHeader }}
            </th>
          </thead>
          <tbody valign="top">
            <tr
              v-for="(cannedItem, index) in records"
              :key="cannedItem.short_code"
            >
              <td v-if="sort" class="flex gap-1">
                <woot-button
                  v-if="sort"
                  icon="chevron-up"
                  color-scheme="secondary"
                  size="tiny"
                  variant="smooth"
                  :disabled="index === 0"
                  @click="moveUp(index)"
                />
                <woot-button
                  v-if="sort"
                  icon="chevron-down"
                  color-scheme="secondary"
                  size="tiny"
                  variant="smooth"
                  :disabled="index === records.length - 1"
                  @click="moveDown(index)"
                />
              </td>
              <td style="width: 100px">
                <div class="flex gap-1 overflow-auto items-center">
                  <div v-if="cannedItem.images" class="images">
                    <img
                      :src="cannedItem.images[0].file_url"
                      :alt="cannedItem.short_code"
                      style="object-fit: cover"
                    />
                  </div>
                </div>
              </td>
              <!-- Short Code  -->
              <td class="w-[8.75rem]">
                {{ cannedItem.short_code }}
              </td>
              <!-- Content -->
              <td class="break-all whitespace-normal">
                {{ cannedItem.content }}
              </td>
              <!-- Action Buttons -->
              <td class="button-wrapper actions">
                <woot-button
                  v-tooltip.top="$t('CANNED_MGMT.EDIT.BUTTON_TEXT')"
                  variant="smooth"
                  size="tiny"
                  color-scheme="secondary"
                  icon="edit"
                  @click="openEditPopup(cannedItem)"
                />
                <woot-button
                  v-tooltip.top="$t('CANNED_MGMT.DELETE.BUTTON_TEXT')"
                  variant="smooth"
                  color-scheme="alert"
                  size="tiny"
                  icon="dismiss-circle"
                  class-names="grey-btn"
                  :is-loading="loading[cannedItem.id]"
                  @click="openDeletePopup(cannedItem, index)"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="w-[34%]">
        <span v-dompurify-html="$t('CANNED_MGMT.SIDEBAR_TXT')" />
      </div>
    </div>
    <!-- Add Agent -->
    <woot-modal :show.sync="showAddPopup" :on-close="hideAddPopup">
      <add-canned :on-close="hideAddPopup" />
    </woot-modal>

    <!-- Edit Canned Response -->
    <woot-modal :show.sync="showEditPopup" :on-close="hideEditPopup">
      <edit-canned
        v-if="showEditPopup"
        :id="selectedResponse.id"
        :edshort-code="selectedResponse.short_code"
        :edcontent="selectedResponse.content"
        :ed-images="selectedResponse.images"
        :on-close="hideEditPopup"
      />
    </woot-modal>

    <!-- Delete Canned Response -->
    <woot-delete-modal
      :show.sync="showDeleteConfirmationPopup"
      :on-close="closeDeletePopup"
      :on-confirm="confirmDeletion"
      :title="$t('CANNED_MGMT.DELETE.CONFIRM.TITLE')"
      :message="$t('CANNED_MGMT.DELETE.CONFIRM.MESSAGE')"
      :message-value="deleteMessage"
      :confirm-text="deleteConfirmText"
      :reject-text="deleteRejectText"
    />
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import SortSection from '../SortSection.vue';
import AddCanned from './AddCanned.vue';
import EditCanned from './EditCanned.vue';
import toggleSortMixin from 'shared/mixins/toggleSortMixin';

export default {
  components: {
    AddCanned,
    EditCanned,
    SortSection,
  },
  mixins: [toggleSortMixin],
  data() {
    return {
      loading: {},
      showAddPopup: false,
      showEditPopup: false,
      showDeleteConfirmationPopup: false,
      selectedResponse: {},
      cannedResponseAPI: {
        message: '',
      },
    };
  },
  computed: {
    ...mapGetters({
      records: 'cannedResponse/getCannedResponses',
      uiFlags: 'cannedResponse/getUIFlags',
    }),
    // Delete Modal
    deleteConfirmText() {
      return `${this.$t('CANNED_MGMT.DELETE.CONFIRM.YES')} ${
        this.selectedResponse.short_code
      }`;
    },
    deleteRejectText() {
      return `${this.$t('CANNED_MGMT.DELETE.CONFIRM.NO')} ${
        this.selectedResponse.short_code
      }`;
    },
    deleteMessage() {
      return ` ${this.selectedResponse.short_code}?`;
    },
    tableName() {
      return 'cannedResponse';
    },
    successMessage() {
      return 'CANNED_MGMT.EDIT.API.SUCCESS_MESSAGE';
    },
    errorMessage() {
      return 'CANNED_MGMT.EDIT.API.ERROR_MESSAGE';
    },
  },
  mounted() {
    // Fetch API Call
    this.$store.dispatch('cannedResponse/getCannedResponse');
  },
  methods: {
    showAlert(message) {
      // Reset loading, current selected agent
      this.loading[this.selectedResponse.id] = false;
      this.selectedResponse = {};
      // Show message
      this.cannedResponseAPI.message = message;
      bus.$emit('newToastMessage', message);
    },
    // Edit Function
    openAddPopup() {
      this.showAddPopup = true;
    },
    hideAddPopup() {
      this.showAddPopup = false;
    },

    // Edit Modal Functions
    openEditPopup(response) {
      this.showEditPopup = true;
      this.selectedResponse = response;
    },
    hideEditPopup() {
      this.showEditPopup = false;
    },

    // Delete Modal Functions
    openDeletePopup(response) {
      this.showDeleteConfirmationPopup = true;
      this.selectedResponse = response;
    },
    closeDeletePopup() {
      this.showDeleteConfirmationPopup = false;
    },
    // Set loading and call Delete API
    confirmDeletion() {
      this.loading[this.selectedResponse.id] = true;
      this.closeDeletePopup();
      this.deleteCannedResponse(this.selectedResponse.id);
    },
    deleteCannedResponse(id) {
      this.$store
        .dispatch('cannedResponse/deleteCannedResponse', id)
        .then(() => {
          this.showAlert(this.$t('CANNED_MGMT.DELETE.API.SUCCESS_MESSAGE'));
        })
        .catch(error => {
          const errorMessage =
            error?.message || this.$t('CANNED_MGMT.DELETE.API.ERROR_MESSAGE');
          this.showAlert(errorMessage);
        });
    },
  },
};
</script>

<style scoped lang="scss">
.images {
  @apply flex;
  height: 60px;
}
</style>
