<template>
  <div
    class="flex flex-1 h-full justify-between flex-col m-0 bg-slate-25 dark:bg-slate-900"
  >
    <settings-header
      button-route="new"
      :header-title="$t('AUTOMATION.EDIT.TITLE')"
      show-back-button
      :back-url="backUrl"
      :show-new-button="false"
      :show-sidemenu-icon="false"
    />
    <div class="flex-1 overflow-auto p-4">
      <div class="row">
        <div v-if="automation" class="medium-12 columns">
          <woot-input
            v-model="automation.name"
            :label="$t('AUTOMATION.ADD.FORM.NAME.LABEL')"
            type="text"
            :class="{ error: $v.automation.name.$error }"
            :error="
              $v.automation.name.$error
                ? $t('AUTOMATION.ADD.FORM.NAME.ERROR')
                : ''
            "
            :placeholder="$t('AUTOMATION.ADD.FORM.NAME.PLACEHOLDER')"
            @blur="$v.automation.name.$touch"
          />
          <woot-input
            v-model="automation.description"
            :label="$t('AUTOMATION.ADD.FORM.DESC.LABEL')"
            type="text"
            :class="{ error: $v.automation.description.$error }"
            :error="
              $v.automation.description.$error
                ? $t('AUTOMATION.ADD.FORM.DESC.ERROR')
                : ''
            "
            :placeholder="$t('AUTOMATION.ADD.FORM.DESC.PLACEHOLDER')"
            @blur="$v.automation.description.$touch"
          />
          <div class="event_wrapper">
            <label :class="{ error: $v.automation.event_name.$error }">
              {{ $t('AUTOMATION.ADD.FORM.EVENT.LABEL') }}
              <select v-model="automation.event_name" @change="onEventChange()">
                <option
                  v-for="event in automationRuleEvents"
                  :key="event.key"
                  :value="event.key"
                >
                  {{ $t(event.value) }}
                </option>
              </select>
              <span v-if="$v.automation.event_name.$error" class="message">
                {{ $t('AUTOMATION.ADD.FORM.EVENT.ERROR') }}
              </span>
            </label>
          </div>
          <!-- // Conditions Start -->
          <section>
            <label>
              {{ $t('AUTOMATION.ADD.FORM.CONDITIONS.LABEL') }}
            </label>
            <div class="medium-12 columns filters-wrap">
              <filter-input-box
                v-for="(condition, i) in automation.conditions"
                :key="i"
                v-model="automation.conditions[i]"
                :filter-attributes="getAttributes(automation.event_name)"
                :input-type="
                  getInputType(automation.conditions[i].attribute_key)
                "
                :operators="
                  getOperators(automation.conditions[i].attribute_key)
                "
                :dropdown-values="
                  getConditionDropdownValues(
                    automation.conditions[i].attribute_key
                  )
                "
                :custom-attribute-type="
                  getCustomAttributeType(automation.conditions[i].attribute_key)
                "
                :show-query-operator="i !== automation.conditions.length - 1"
                :v="$v.automation.conditions.$each[i]"
                @resetFilter="resetFilter(i, automation.conditions[i])"
                @removeFilter="removeFilter(i)"
              />
              <div class="filter-actions">
                <woot-button
                  icon="add"
                  color-scheme="success"
                  variant="smooth"
                  size="small"
                  @click="appendNewCondition"
                >
                  {{ $t('AUTOMATION.ADD.CONDITION_BUTTON_LABEL') }}
                </woot-button>
              </div>
            </div>
          </section>
          <!-- // Conditions End -->
          <!-- // Actions Start -->
          <section>
            <label>
              {{ $t('AUTOMATION.ADD.FORM.ACTIONS.LABEL') }}
            </label>
            <div class="medium-12 columns filters-wrap">
              <automation-action-input
                v-for="(action, i) in automation.actions"
                :key="i"
                v-model="automation.actions[i]"
                :action-types="getAutoMationActionTypeByFeatureFlag"
                :dropdown-values="
                  getActionDropdownValues(
                    action.action_name,
                    action.action_params
                  )
                "
                :show-action-input="showActionInput(action.action_name)"
                :v="$v.automation.actions.$each[i]"
                :initial-file-name="getFileName(action, automation.files)"
                :index="i"
                @resetAction="resetAction(i)"
                @removeAction="removeAction(i)"
                @on-select="filterSelectOption(action)"
              />
              <div class="filter-actions">
                <woot-button
                  icon="add"
                  color-scheme="success"
                  variant="smooth"
                  size="small"
                  @click="appendNewAction"
                >
                  {{ $t('AUTOMATION.ADD.ACTION_BUTTON_LABEL') }}
                </woot-button>
              </div>
            </div>
          </section>
          <!-- // Actions End -->
          <div class="medium-12 columns">
            <div class="modal-footer justify-content-end w-full">
              <woot-button @click="submitAutomation">
                {{ $t('AUTOMATION.EDIT.SUBMIT') }}
              </woot-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import alertMixin from 'shared/mixins/alertMixin';
import automationMethodsMixin from 'dashboard/mixins/automations/methodsMixin';
import automationValidationsMixin from 'dashboard/mixins/automations/validationsMixin';
import filterInputBox from 'dashboard/components/widgets/FilterInput/Index.vue';
import automationActionInput from 'dashboard/components/widgets/AutomationActionInput.vue';
import { mapGetters } from 'vuex';
import {
  AUTOMATION_RULE_EVENTS,
  AUTOMATION_ACTION_TYPES,
  AUTOMATIONS,
} from './constants';
import SettingsHeader from '../settings/SettingsHeader.vue';
import { generateAutomationPayload } from 'dashboard/helper/automationHelper';

export default {
  components: {
    filterInputBox,
    automationActionInput,
    SettingsHeader,
  },
  mixins: [alertMixin, automationMethodsMixin, automationValidationsMixin],
  data() {
    return {
      automationTypes: JSON.parse(JSON.stringify(AUTOMATIONS)),
      automationRuleEvent: AUTOMATION_RULE_EVENTS[0].key,
      automationRuleEvents: AUTOMATION_RULE_EVENTS,
      automationActionTypes: AUTOMATION_ACTION_TYPES,
      automationMutated: false,
      show: true,
      automation: null,
      showDeleteConfirmationModal: false,
      allCustomAttributes: [],
      mode: 'edit',
      messageTypesSelected: [],
    };
  },
  computed: {
    ...mapGetters({
      accountId: 'getCurrentAccountId',
      isFeatureEnabledonAccount: 'accounts/isFeatureEnabledonAccount',
      automationUIFlags: 'automations/getUIFlags',
      messageTypeUIFlag: 'messageTypes/getUIFlags',
    }),
    hasAutomationMutated() {
      if (
        this.automation.conditions[0].values ||
        this.automation.actions[0].action_params.length
      )
        return true;
      return false;
    },
    getAutoMationActionTypeByFeatureFlag() {
      return this.automationActionTypes.filter(actionType => {
        if (actionType.featureFlag) {
          return this.isFeatureEnabledonAccount(
            this.accountId,
            actionType.featureFlag
          );
        }

        return true;
      });
    },
    backUrl() {
      return `/app/accounts/${this.$route.params.accountId}/automation`;
    },
    isLoading() {
      return (
        this.automationUIFlags.isFetching || this.messageTypeUIFlag.isFetching
      );
    },
  },
  mounted() {
    this.fetchAutomation();
    this.manifestCustomAttributes();
    this.allCustomAttributes = this.$store.getters['attributes/getAttributes'];
  },
  methods: {
    async fetchAutomation() {
      const automationId = Number(this.$route.params.automationId);
      await this.$store.dispatch('messageTypes/get', {
        per: 100,
        without_inactive: true,
      });
      await this.$store.dispatch('agents/get', { per: 100 });
      await this.$store.dispatch(
        'automations/getSingleAutomation',
        automationId
      );
      const automation =
        this.$store.getters['automations/getAutomation'](automationId);
      this.formatAutomation(automation);
    },
    async submitAutomation() {
      this.$v.$touch();
      if (this.$v.$invalid) return;

      try {
        await this.$store.dispatch(
          'automations/update',
          generateAutomationPayload(this.automation)
        );
        this.showAlert(this.$t('AUTOMATION.EDIT.API.SUCCESS_MESSAGE'));
        this.$router.push({
          name: 'automation',
        });
      } catch (error) {
        this.showAlert(this.$t('AUTOMATION.EDIT.API.ERROR_MESSAGE'));
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.filters-wrap {
  padding: var(--space-normal);
  border-radius: var(--border-radius-large);
  border: 1px solid var(--color-border);
  margin-bottom: var(--space-normal);
}

.filter-actions {
  margin-top: var(--space-normal);
}
.event_wrapper {
  select {
    margin: var(--space-zero);
  }
  .info-message {
    font-size: var(--font-size-mini);
    color: var(--s-500);
    text-align: right;
  }
  margin-bottom: var(--space-medium);
}
</style>
