<template>
  <woot-modal :show.sync="show" :on-close="onClose">
    <woot-modal-header :header-title="messageType.title" />
    <p class="text-center">
      {{ getActionTypeTag(messageType.type) }} {{ t$('MESSAGE_TYPE.PREVIEW') }}
    </p>
    <div class="message-type-container">
      <div
        v-for="(actionTypeTemplate, index) in actionTypeTemplates"
        :key="index"
        :class="`message-type-item message-type-${index}`"
      >
        <bubble-card-message
          v-if="
            messageType.type === 'card_message' ||
              messageType.type === 'carousel'
          "
          :title="actionTypeTemplate.title"
          :subtitle="actionTypeTemplate.subtitle"
          :cover-image="actionTypeTemplate.image_url"
          :buttons="actionTypeTemplate.buttons"
        />
        <bubble-quick-reply
          v-if="messageType.type === 'quick_reply'"
          :title="actionTypeTemplate.title"
          :buttons="actionTypeTemplate.buttons"
        />
      </div>
    </div>

    <br />
    <div class="text-center">
      <woot-button type="button" @click="onClickSend">
        {{ $t('CONVERSATION.REPLYBOX.SEND') }}
      </woot-button>
    </div>
    <br />
  </woot-modal>
</template>

<script>
import { getActionTypeName } from '../../../../widget/helpers/constants';
import BubbleCardMessage from './bubble/CardMessage';
import BubbleQuickReply from './bubble/QuickReply';

export default {
  components: {
    BubbleCardMessage,
    BubbleQuickReply,
  },
  props: {
    messageType: {
      type: Object,
      required: true,
    },
    show: {
      type: Boolean,
      default: false,
    },
    onClickSend: {
      type: Function,
      default: () => {},
    },
  },
  computed: {
    actionTypeTemplates() {
      return this.messageType.payload;
    },
  },
  methods: {
    onClose() {
      this.$emit('close');
    },
    getActionTypeTag(tagName) {
      return getActionTypeName(tagName);
    },
  },
};
</script>

<style lang="scss" scoped>
.message-type-container {
  padding: 15px;
  display: flex;
  overflow: scroll;
  gap: 10px;
}
</style>
