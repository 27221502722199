import { getActionTypeName } from '../../../../../widget/helpers/constants';

export default {
  methods: {
    getActionTypeTag(type) {
      return getActionTypeName(type);
    },
    getTagClass(tagName) {
      return `option__tag ${tagName}`;
    },
  },
};
