export const MACRO_ACTION_TYPES = [
  {
    key: 'assign_team',
    label: 'MACROS.EDITOR.INPUT_TYPE_OPTIONS.ASSIGN_A_TEAM',
    inputType: 'search_select',
  },
  {
    key: 'assign_agent',
    label: 'MACROS.EDITOR.INPUT_TYPE_OPTIONS.ASSIGN_AN_AGENT',
    inputType: 'search_select',
  },
  {
    key: 'add_label',
    label: 'MACROS.EDITOR.INPUT_TYPE_OPTIONS.ADD_LABEL',
    inputType: 'multi_select',
    locale: 'MACROS.EDITOR.INPUT_TYPE_OPTIONS.ADD_LABEL',
  },
  {
    key: 'remove_label',
    label: 'MACROS.EDITOR.INPUT_TYPE_OPTIONS.REMOVE_LABEL',
    inputType: 'multi_select',
  },
  {
    key: 'remove_assigned_team',
    label: 'MACROS.EDITOR.INPUT_TYPE_OPTIONS.REMOVE_ASSIGNED_TEAM',
    inputType: null,
  },
  {
    key: 'send_email_transcript',
    label: 'MACROS.EDITOR.INPUT_TYPE_OPTIONS.SEND_TRANSCRIPT',
    inputType: 'email',
  },
  {
    key: 'mute_conversation',
    label: 'MACROS.EDITOR.INPUT_TYPE_OPTIONS.MUTE_CONVERSATION',
    inputType: null,
  },
  {
    key: 'snooze_conversation',
    label: 'MACROS.EDITOR.INPUT_TYPE_OPTIONS.SNOOZE_CONVERSATION',
    inputType: null,
  },
  {
    key: 'resolve_conversation',
    label: 'MACROS.EDITOR.INPUT_TYPE_OPTIONS.RESOLVE_CONVERSATION',
    inputType: null,
  },
  {
    key: 'send_attachment',
    label: 'MACROS.EDITOR.INPUT_TYPE_OPTIONS.SEND_ATTACHMENT',
    inputType: 'attachment',
  },
  {
    key: 'send_message',
    label: 'MACROS.EDITOR.INPUT_TYPE_OPTIONS.SEND_MESSAGE',
    inputType: 'textarea',
  },
  {
    key: 'add_private_note',
    label: 'MACROS.EDITOR.INPUT_TYPE_OPTIONS.ADD_A_PRIVATE_NOTE',
    inputType: 'textarea',
  },
  {
    key: 'change_priority',
    label: 'MACROS.EDITOR.INPUT_TYPE_OPTIONS.CHANGE_PRIORITY',
    inputType: 'search_select',
  },
];
