<template>
  <main
    class="flex flex-col bg-woot-50 min-h-screen w-full py-20 sm:px-6 lg:px-8 dark:bg-slate-900"
  >
    <div
      v-if="!uiFlags.isFetching"
      class="bg-white shadow sm:mx-auto mt-11 sm:w-full sm:max-w-lg dark:bg-slate-800 p-11 sm:shadow-lg sm:rounded-lg"
    >
      <h3
        class="mt-6 text-center text-3xl font-medium text-woot-500 dark:text-woot-50"
      >
        {{ $t('CONTACT_ATTRIBUTES.FORM.TITLE') }}
      </h3>
      <!-- <custom-attribute
        v-model="phoneNumber"
        attribute-key="phoneNumber"
        attribute-type="number"
        :label="$t('CONTACT_ATTRIBUTES.FORM.PHONE_NUMBER')"
        :value="phoneNumber"
        :show-actions="true"
        :class="attributeClass"
        :allowed-delete="false"
        :hold-edit="true"
        @update="onUpdateData"
      /> -->
      <div v-for="attribute in attributes" :key="attribute.id">
        <custom-attribute
          v-model="customAttribute[attribute.attribute_key]"
          :attribute-key="attribute.attribute_key"
          :attribute-type="attribute.attribute_display_type"
          :values="attribute.attribute_values"
          :label="attribute.attribute_display_name"
          :description="attribute.attribute_description"
          :icon="attribute.icon"
          emoji=""
          :value="attribute.value"
          :show-actions="true"
          :class="attributeClass"
          :allowed-delete="false"
          :hold-edit="true"
          @update="onUpdateData"
        />
      </div>
      <div class="form-footer">
        <woot-button
          :is-loading="isExecuting"
          @click="submitConversationAttribute"
        >
          {{ $t('CONTACT_ATTRIBUTES.FORM.SUBMIT') }}
        </woot-button>
      </div>
    </div>
    <spinner v-else />
  </main>
</template>

<script>
import CustomAttribute from './CustomAttribute.vue';
import Spinner from 'shared/components/Spinner';
import alertMixin from 'shared/mixins/alertMixin';
import format from 'date-fns/format';
import { mapGetters } from 'vuex';
const DATE_FORMAT = 'yyyy-MM-dd';

export default {
  components: { CustomAttribute, Spinner },
  mixins: [alertMixin],
  data() {
    return {
      isLoading: true,
      termCondition: false,
      policyTerm: false,
      additionalLinkTerm: true,
      customAttribute: {},
      canSubmit: false,
      isExecuting: false,
      phoneNumber: '',
    };
  },
  computed: {
    ...mapGetters({
      attributes: 'conversationAttributes/getConversationAttributes',
      uiFlags: 'conversationAttributes/getUIFlags',
      customAttributes: 'conversationAttributes/getAccountCustomAttributes',
    }),
    attributeClass() {
      return 'edit-contact-attribute';
    },
    checkPolicy() {
      return this.policyTerm && this.termCondition && this.additionalLinkTerm;
    },
    attributesLength() {
      return this.attributes.length;
    },
    policyLink() {
      return window.chatwootConfig.policyLink;
    },
    termLink() {
      return window.chatwootConfig.termLink;
    },
    haveAdditionalLink() {
      return this.customAttributes && this.customAttributes.additional_link;
    },
  },
  watch: {
    customAttributes(customAttributes) {
      if (customAttributes && customAttributes.additional_link) {
        this.additionalLinkTerm = false;
      }
    },
  },
  mounted() {
    try {
      this.fetchAttributes();
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      this.showAlert(this.$t('CONTACT_ATTRIBUTES.LOADING.ERROR'));
    } finally {
      this.isLoading = false;
    }
  },
  methods: {
    setLocale(locale) {
      this.$root.$i18n.locale = locale;
    },
    async fetchAttributes() {
      await this.$store.dispatch('conversationAttributes/get');
      this.initialCustomAttribute();
      this.setCurrentAccount();
    },
    customAttributeLength() {
      return Object.keys(this.customAttribute).length;
    },
    validData() {
      this.canSubmit = !Object.values(this.customAttribute).includes(null);
    },
    initialCustomAttribute() {
      this.attributes.forEach(attribute => {
        const { attribute_key, attribute_display_type } = attribute;
        if (attribute_display_type === 'date') {
          this.onUpdateData(attribute_key, format(new Date(), DATE_FORMAT));
        } else if (attribute_display_type === 'checkbox') {
          this.onUpdateData(attribute_key, false);
        } else {
          this.onUpdateData(attribute_key, null);
        }
      });
    },
    async setCurrentAccount() {
      if (this.customAttribute && this.customAttribute.additional_link) {
        this.additionalLinkTerm = false;
      }
    },
    onUpdateData(key, value) {
      if (key === 'phoneNumber') {
        this.phoneNumber = value;
      } else {
        this.customAttribute[key] = value;
      }
      this.canSubmit = !Object.values(this.customAttribute).includes(null);
    },
    async submitConversationAttribute() {
      try {
        this.isExecuting = true;
        await this.$store.dispatch('conversationAttributes/update', {
          accountId: this.$route.params.accountId,
          conversationId: this.$route.params.conversationId,
          customAttributes: this.customAttribute,
          phoneNumber: this.phoneNumber,
        });
        this.showAlert(
          this.$t('CONTACT_ATTRIBUTES.API.UPDATE.SUCCESS_MESSAGE')
        );
        this.$router.push({
          name: 'edit_conversation_attributes_successful',
        });
      } catch (error) {
        this.showAlert(this.$t('CONTACT_ATTRIBUTES.API.UPDATE.ERROR_MESSAGE'));
      } finally {
        this.isExecuting = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
