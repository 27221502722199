<template>
  <div
    class="flex justify-between items-center h-14 min-h-[3.5rem] px-4 py-2 bg-white dark:bg-slate-900 border-b border-slate-50 dark:border-slate-800/50 header-wrapper"
  >
    <div class="header">
      <h1
        class="text-2xl mb-0 flex items-center text-slate-900 dark:text-slate-100"
      >
        {{ pageTitle }}
      </h1>
    </div>
    <div class="cta">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    pageTitle: {
      type: String,
      default: '',
      require: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.header-wrapper {
  padding: var(--space-small) var(--space-normal);
  display: flex;
  justify-content: space-between;
}
</style>
