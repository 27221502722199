import { frontendURL } from '../../helper/URLHelper';
import EditConversationAttributes from './EditConversationAttributes.vue';
import Successful from './Successful.vue';

export default {
  routes: [
    {
      path: frontendURL(
        'accounts/:accountId/conversations/:conversationId/attributes/edit'
      ),
      name: 'edit_conversation_attributes',
      roles: ['administrator'],
      component: EditConversationAttributes,
    },
    {
      path: frontendURL(
        'accounts/:accountId/conversations/:conversationId/attributes/success'
      ),
      name: 'edit_conversation_attributes_successful',
      roles: ['administrator'],
      component: Successful,
    },
  ],
};
