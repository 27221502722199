/* global axios */
import ApiClient from './ApiClient';

class Agents extends ApiClient {
  constructor() {
    super('agents', { accountScoped: true });
  }

  updateOrder(agents) {
    return axios.patch(`${this.url}/update_order`, { agents });
  }
}

export default new Agents();
