var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-wrapper"},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit()}}},[_c('div',{staticClass:"form-left-side"},[_c('div',{staticClass:"columns"},[_c('label',{class:{ error: _vm.$v.messageType.title.$error }},[_vm._v("\n          "+_vm._s(_vm.$t('MESSAGE_TYPE.FORM.TITLE.LABEL'))+"\n          "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.messageType.title),expression:"messageType.title"}],attrs:{"type":"text","placeholder":_vm.$t('MESSAGE_TYPE.FORM.TITLE.PLACEHOLDER')},domProps:{"value":(_vm.messageType.title)},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.messageType, "title", $event.target.value)},_vm.$v.messageType.title.$touch]}})]),_vm._v(" "),_c('label',{class:{
            error:
              _vm.$v.messageType.keyword.$error || (_vm.errors && _vm.errors.keyword),
          }},[_vm._v("\n          "+_vm._s(_vm.$t('MESSAGE_TYPE.FORM.KEYWORD.LABEL'))+"\n          "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.messageType.keyword),expression:"messageType.keyword"}],attrs:{"type":"text","maxlength":30,"placeholder":_vm.$t('MESSAGE_TYPE.FORM.KEYWORD.PLACEHOLDER')},domProps:{"value":(_vm.messageType.keyword)},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.messageType, "keyword", $event.target.value)},_vm.$v.messageType.keyword.$touch]}}),_vm._v(" "),_c('span',{staticClass:"limitedText"},[_vm._v(_vm._s(_vm.messageType.keyword.length || 0)+"/30")]),_vm._v(" "),(_vm.errors && _vm.errors.keyword)?_c('span',{staticClass:"error-message"},[_vm._v("\n            "+_vm._s(_vm.errors.keyword)+"\n          ")]):_vm._e()]),_vm._v(" "),(_vm.isQuickReplyType)?_c('label',[_vm._v("\n          "+_vm._s(_vm.$t('MESSAGE_TYPE.FORM.INITIAL_MESSAGE.LABEL'))+"\n          "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.messageType.initialMessage),expression:"messageType.initialMessage"}],attrs:{"type":"text","placeholder":_vm.$t('MESSAGE_TYPE.FORM.INITIAL_MESSAGE.PLACEHOLDER')},domProps:{"value":(_vm.messageType.initialMessage)},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.messageType, "initialMessage", $event.target.value)},_vm.$v.messageType.initialMessage.$touch]}})]):_vm._e(),_vm._v(" "),_c('label',[_vm._v("\n          "+_vm._s(_vm.$t('MESSAGE_TYPE.FORM.ACTIVE.LABEL'))+"\n          "),_c('div',{staticClass:"message-status"},[_c('woot-switch',{attrs:{"value":_vm.messageType.active},on:{"input":_vm.toggleMessageType}}),_vm._v("\n            "+_vm._s(_vm.messageType.active
                ? _vm.$t('MESSAGE_TYPE.FORM.ACTIVE.YES')
                : _vm.$t('MESSAGE_TYPE.FORM.ACTIVE.NO'))+"\n          ")],1)]),_vm._v(" "),_c('div',{staticClass:"message-type-list"},[_c('div',{staticClass:"message-type-title"},[_c('span',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t(
                `MESSAGE_TYPE.FORM.MESSAGE_TYPES_LIST.${_vm.messageTypeUpperCase}.TITLE`
              )))]),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.messageTypeLength || 0)+"/10")])]),_vm._v(" "),_c('div',{staticClass:"template-container"},_vm._l((_vm.messageType.templates),function(template,index){return _c('div',{key:index,class:`template-item ${_vm.getTemplateActiveClass(index)}`},[_c('h6',{staticClass:"text-slate-900 dark:text-slate-100",on:{"click":function($event){return _vm.selectedTemplate(index)}}},[_vm._v("\n                "+_vm._s(_vm.templateTitle(template))+"\n              ")]),_vm._v(" "),(_vm.hasAtLeastOneTemplate)?_c('button',{attrs:{"type":"button"},on:{"click":function($event){return _vm.removeTemplate(index)}}},[_c('emoji-or-icon',{attrs:{"icon":"dismiss","variant":"clear"}})],1):_vm._e()])}),0)])])]),_vm._v(" "),_c('div',{staticClass:"form-right-side"},[_c('div',{staticClass:"preview-container"},[_vm._l((_vm.messageType.templates),function(template,index){return _c('div',{key:index},[(_vm.isCardMessage)?_c('bubble-card-message',{attrs:{"title":template.title,"subtitle":template.subtitle,"cover-image":template.image_url ||
              '/assets/images/dashboard/message-types/card-bg.png',"buttons":template.buttons || [],"validate-state":_vm.$v,"mode-show":"EDIT","active":_vm.isTemplateActive(index)},on:{"update:title":function($event){return _vm.$set(template, "title", $event)},"update:subtitle":function($event){return _vm.$set(template, "subtitle", $event)},"upload-image":_vm.setImageToTemplate,"select-card":function($event){return _vm.selectedTemplate(index)}}}):_vm._e(),_vm._v(" "),(_vm.isQuickReplyType)?_c('bubble-quick-reply',{attrs:{"title":template.title,"buttons":template.buttons || [],"active":_vm.isTemplateActive(index)},on:{"on-click-button":() => _vm.selectedTemplate(index)}}):_vm._e()],1)}),_vm._v(" "),_c('div',[(!_vm.reachALimitTemplate)?_c('div',{class:`new_${_vm.messageTypeName}`,on:{"click":_vm.addTemplate}},[_vm._v("\n            "+_vm._s(_vm.$t(`MESSAGE_TYPE.FORM.NEW_${_vm.messageTypeUpperCase}`))+"\n          ")]):_vm._e()])],2),_vm._v(" "),_vm._l((_vm.messageType.templates[_vm.selectedTemplateIndex]
          .buttons || []),function(button,index){return _c('div',{key:index,class:`button-form for-${_vm.messageTypeName}`},[_c('div',{staticClass:"button-active"},[(_vm.isCardMessage)?_c('label',[_c('div',{staticClass:"message-status"},[_c('woot-switch',{attrs:{"value":!button.action.hide},on:{"input":function($event){return _vm.toggleButtonActive(index)}}}),_vm._v("\n              "+_vm._s(_vm.$t(`MESSAGE_TYPE.FORM.BUTTON.STATUS_${index + 1}.LABEL`))+"\n            ")],1)]):_vm._e()]),_vm._v(" "),(!button.action.hide)?_c('div',{staticClass:"form__button-detail"},[_c('div',{staticClass:"flex flex-1 gap-1"},[(_vm.isCardMessage)?_c('label',{staticClass:"flex-1"},[_vm._v("\n              "+_vm._s(_vm.$t('MESSAGE_TYPE.FORM.BUTTON.BUTTON_TYPE'))+"\n              "),_c('select',{directives:[{name:"model",rawName:"v-model",value:(button.buttonType),expression:"button.buttonType"}],attrs:{"disabled":"true"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(button, "buttonType", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},_vm._l((_vm.buttonTypeOptions),function(option,indexOption){return _c('option',{key:indexOption,domProps:{"value":option.value}},[_vm._v("\n                  "+_vm._s(option.label)+"\n                ")])}),0)]):_vm._e(),_vm._v(" "),_c('label',{class:{
                active:
                  _vm.$v.messageType.templates.$each[_vm.selectedTemplateIndex]
                    .buttons.$each[index].label.$error,
                'flex-1': true,
              }},[_vm._v("\n              "+_vm._s(_vm.$t('MESSAGE_TYPE.FORM.BUTTON.LABEL.TITLE'))+"\n              "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(button.label),expression:"button.label"}],attrs:{"type":"text","maxlength":15,"placeholder":_vm.$t('MESSAGE_TYPE.FORM.BUTTON.LABEL.PLACEHOLDER')},domProps:{"value":(button.label)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(button, "label", $event.target.value)}}}),_vm._v(" "),_c('span',{staticClass:"limitedText"},[_vm._v(_vm._s(button.label.length || 0)+"/15")])])]),_vm._v(" "),_c('div',{staticClass:"flex flex-1 gap-1"},[_c('label',{staticClass:"flex-1"},[_vm._v("\n              "+_vm._s(_vm.$t('MESSAGE_TYPE.FORM.BUTTON.ACTION_TYPE'))+"\n              "),_c('select',{directives:[{name:"model",rawName:"v-model",value:(button.action.type),expression:"button.action.type"}],on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(button.action, "type", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},_vm._l((_vm.actionOptions),function(option,indexOption){return _c('option',{key:indexOption,domProps:{"value":option.value}},[_vm._v("\n                  "+_vm._s(_vm.$t(option.label))+"\n                ")])}),0)]),_vm._v(" "),_c('div',{staticClass:"action_input_wrap flex-1"},[_c('label',[_vm._v("\n                "+_vm._s(_vm.$t(
                    `MESSAGE_TYPE.FORM.BUTTON.PAYLOAD.${button.action.type.toUpperCase()}.LABEL`
                  ))+"\n              ")]),_vm._v(" "),(
                  ['url', 'postback', 'text'].includes(button.action.type)
                )?_c('input',{directives:[{name:"model",rawName:"v-model",value:(button.action[button.action.type]),expression:"button.action[button.action.type]"}],attrs:{"type":"text","maxlength":button.action.type === 'text' ? 50 : undefined,"placeholder":_vm.$t(
                    `MESSAGE_TYPE.FORM.BUTTON.PAYLOAD.${button.action.type.toUpperCase()}.PLACEHOLDER`
                  )},domProps:{"value":(button.action[button.action.type])},on:{"input":function($event){if($event.target.composing)return;_vm.$set(button.action, button.action.type, $event.target.value)}}}):_c('message-type-select',{attrs:{"index":index,"data-selected":button.action[button.action.type],"options":_vm.getPayloadOption(button.action.type)},on:{"update:dataSelected":function($event){return _vm.$set(button.action, button.action.type, $event)},"update:data-selected":function($event){return _vm.$set(button.action, button.action.type, $event)}}}),_vm._v(" "),(button.action.type === 'text')?_c('span',{staticClass:"limitedText"},[_vm._v("\n                "+_vm._s(button.action[button.action.type].length || 0)+"/50\n              ")]):_vm._e()],1)])]):_vm._e()])})],2),_vm._v(" "),_c('button',{ref:"form",staticClass:"hidden-button",attrs:{"type":"submit"}})]),_vm._v(" "),_c('woot-confirm-modal',{ref:"confirmDialog",attrs:{"title":_vm.$t('MESSAGE_TYPE.DELETE.TITLE'),"description":_vm.$t('MESSAGE_TYPE.DELETE.DESCRIPTION')}}),_vm._v(" "),_c('message-type-preview-modal',{attrs:{"show":_vm.previewModal,"message-type":_vm.messageType},on:{"on-close":() => _vm.$emit('on-close-preview-modal')}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }