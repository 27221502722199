<template>
  <modal :show="show" :on-close="closeModal">
    <div class="warning-container">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="67"
        height="67"
        viewBox="0 0 67 67"
        fill="none"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M11.0003 6.83341C8.69914 6.83341 6.83366 8.6989 6.83366 11.0001V40.3479C6.83366 42.6491 8.69914 44.5146 11.0003 44.5146H26.0003C27.2831 44.5146 28.4521 45.2507 29.0064 46.4075L33.6246 56.0455L38.6961 46.3081C39.2702 45.2059 40.4098 44.5146 41.6525 44.5146H56.0003C58.3015 44.5146 60.167 42.6491 60.167 40.3479V11.0001C60.167 8.69889 58.3015 6.83341 56.0003 6.83341H11.0003ZM0.166992 11.0001C0.166992 5.017 5.01724 0.166748 11.0003 0.166748H56.0003C61.9834 0.166748 66.8337 5.017 66.8337 11.0001V40.3479C66.8337 46.331 61.9834 51.1812 56.0003 51.1812H43.6747L36.4567 65.0399C35.8734 66.1598 34.7073 66.854 33.4448 66.8329C32.1822 66.8119 31.0399 66.0792 30.4943 64.9405L23.9013 51.1812H11.0003C5.01724 51.1812 0.166992 46.331 0.166992 40.3479V11.0001ZM33.5003 11.4167C35.3413 11.4167 36.8337 12.9091 36.8337 14.7501V26.0001C36.8337 27.841 35.3413 29.3334 33.5003 29.3334C31.6594 29.3334 30.167 27.841 30.167 26.0001V14.7501C30.167 12.9091 31.6594 11.4167 33.5003 11.4167ZM33.5003 33.6348C35.3413 33.6348 36.8337 35.1271 36.8337 36.9681V37.2501C36.8337 39.091 35.3413 40.5834 33.5003 40.5834C31.6594 40.5834 30.167 39.091 30.167 37.2501V36.9681C30.167 35.1271 31.6594 33.6348 33.5003 33.6348Z"
          fill="#FCCA49"
        />
      </svg>
      <br />
      <p>
        <b>{{ title }}</b>
      </p>
      <br />
      <h3 class="primary">{{ confirmValue }}</h3>

      <p>
        {{ $t('MESSAGE_TYPE.WARNING.DESCRIPTION') }}
      </p>
      <br />
      <div class="automation-list">
        <p class="used_in">{{ $t('MESSAGE_TYPE.WARNING.USED_IN') }}</p>
        <p v-if="messageType.automation_rules.length" class="action-list-title">
          {{ $t('MESSAGE_TYPE.WARNING.AUTOMATION') }}
        </p>
        <p
          v-for="automation in messageType.automation_rules"
          :key="automation.id"
        >
          {{ automation.name }}
        </p>
        <p v-if="messageType.in_used.length" class="action-list-title">
          {{ $t('MESSAGE_TYPE.WARNING.ACTION_MESSAGES') }}
        </p>
        <p v-for="actionMessage in messageType.in_used" :key="actionMessage.id">
          {{ actionMessage.title }}
        </p>
      </div>
    </div>

    <form @submit.prevent="onConfirm">
      <woot-input
        v-model="value"
        type="text"
        :class="{ error: $v.value.$error }"
        :placeholder="confirmPlaceHolderText"
        @blur="$v.value.$touch"
      />
      <div class="button-wrapper">
        <woot-button color-scheme="alert" :is-disabled="$v.value.$invalid">
          {{ confirmText }}
        </woot-button>
        <woot-button class="clear" @click.prevent="closeModal">
          {{ rejectText }}
        </woot-button>
      </div>
    </form>
  </modal>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import Modal from '../../../../../dashboard/components/Modal.vue';

export default {
  components: {
    Modal,
  },

  props: {
    show: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    message: {
      type: String,
      default: '',
    },
    confirmText: {
      type: String,
      default: '',
    },
    rejectText: {
      type: String,
      default: '',
    },
    confirmValue: {
      type: String,
      default: '',
    },
    confirmPlaceHolderText: {
      type: String,
      default: '',
    },
    messageType: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      value: '',
    };
  },
  validations: {
    value: {
      required,
      isEqual(value) {
        return value === this.confirmValue;
      },
    },
  },
  methods: {
    closeModal() {
      this.value = '';
      this.$emit('on-close');
    },
    onConfirm() {
      this.$emit('on-confirm');
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-container .modal-footer {
  padding: var(--space-normal) var(--space-medium);
}

.confirm-input {
  padding: var(--space-normal) var(--space-medium);
  > input {
    margin: 0;
  }
}

.warning-container {
  padding: var(--space-large) var(--space-large);
  text-align: center;

  .automation-list {
    text-align: left;
  }
}

form {
  padding-top: 1rem;
}

.primary {
  color: var(--w-500);
}

.used_in {
  color: var(--color-medium-gray);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 100% */
  letter-spacing: 0.96px;
}

.action-list-title {
  color: var(--color-medium-gray);
  font-family: Inter;
  font-size: 12px;
  font-style: italic;
  font-weight: 700;
  line-height: normal;
  padding: 1rem 0;
}
</style>
