<template>
  <dashboard-app-frame :config="getConfig" :is-visible="true" :position="1" />
</template>

<script>
import DashboardAppFrame from '../../../components/widgets/DashboardApp/Frame.vue';

export default {
  components: { DashboardAppFrame },
  computed: {
    getConfig() {
      return [
        {
          type: 'frame',
          url: `${process.env.VUE_APP_BOOKING_FRONTEND_URL}/event-types`,
        },
      ];
    },
  },
};
</script>
