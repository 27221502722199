<template>
  <div class="content-box column table-container">
    <p v-if="!records.length">{{ $t('MESSAGE_TYPE.LIST.NO_MESSAGE_TYPES') }}</p>
    <table v-else class="woot-table">
      <thead>
        <th
          v-for="tableHeader in $t('MESSAGE_TYPE.LIST.TABLE_HEADER')"
          :key="tableHeader"
          class="item"
        >
          {{ tableHeader }}
        </th>
      </thead>
      <tbody>
        <tr v-for="record in records" :key="record.id">
          <td>
            {{ record.title }}
          </td>
          <td>
            {{ findMessageTypeName(record.type).label }}
          </td>
          <td>
            <woot-button
              variant="smooth"
              size="tiny"
              color-scheme="secondary"
              class-names="grey-btn"
              icon="edit"
              :is-loading="!!loading[record.id]"
              @click="
                () => {
                  $router.push({
                    name: 'edit_message_type',
                    params: { messageTypeId: record.id },
                  });
                }
              "
            />
            <woot-button
              variant="smooth"
              color-scheme="alert"
              size="tiny"
              icon="dismiss-circle"
              class-names="grey-btn"
              :is-loading="!!loading[record.id]"
              @click="handleDelete(record)"
            />
          </td>
        </tr>
      </tbody>
    </table>

    <table-footer
      :current-page="Number(meta.currentPage)"
      :total-count="meta.count"
      :page-size="10"
      @page-change="onPageChange"
    />
    <woot-confirm-delete-modal
      v-if="showDeletePopup"
      :show.sync="showDeletePopup"
      :title="$t('MESSAGE_TYPE.DELETE.TITLE')"
      :message="
        $t('MESSAGE_TYPE.DELETE.DESCRIPTION', { name: getResponseName })
      "
      :confirm-text="$t('MESSAGE_TYPE.DELETE.YES', { name: getResponseName })"
      :reject-text="$t('MESSAGE_TYPE.DELETE.NO', { name: getResponseName })"
      :confirm-value="getResponseName"
      :confirm-place-holder-text="
        $t('MESSAGE_TYPE.DELETE.PLACEHOLDER', { name: getResponseName })
      "
      @on-confirm="confirmDeletion"
      @on-close="closeDelete"
    />
    <warning-and-confirm-delete-modal
      v-if="selectedResponse"
      :show.sync="showDeleteWithWarningModal"
      :confirm-value="getResponseName"
      :title="$t('MESSAGE_TYPE.DELETE.TITLE')"
      :message-type="selectedResponse"
      :confirm-text="$t('MESSAGE_TYPE.DELETE.YES', { name: getResponseName })"
      :reject-text="$t('MESSAGE_TYPE.DELETE.NO', { name: getResponseName })"
      :confirm-place-holder-text="
        $t('MESSAGE_TYPE.DELETE.PLACEHOLDER', { name: getResponseName })
      "
      @on-confirm="confirmDeletion"
      @on-close="closeDelete"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { ACTION_MESSAGES } from '../../../../../widget/helpers/constants';
import TableFooter from '../../../../components/widgets/TableFooter.vue';
import WarningAndConfirmDeleteModal from './WarningAndConfirmDeleteModal.vue';

export default {
  components: { WarningAndConfirmDeleteModal, TableFooter },
  data() {
    return {
      selectedPageNumber: 1,
      searchQuery: '',
      selectedResponse: null,
      showDeletePopup: false,
      showDeleteWithWarningModal: false,
      loading: {},
    };
  },
  computed: {
    ...mapGetters({
      records: 'messageTypes/getMessageTypes',
      meta: 'messageTypes/getMeta',
    }),
    getResponseName() {
      return this.selectedResponse?.title || '';
    },
  },
  mounted() {
    this.fetchMessageTypes();
  },
  methods: {
    fetchMessageTypes() {
      this.$store.dispatch('messageTypes/get', {
        page: this.selectedPageNumber,
        keyword: this.searchQuery,
      });
    },
    findMessageTypeName(actionName) {
      return ACTION_MESSAGES.find(
        actionMessage => actionMessage.name === actionName
      );
    },
    handleDelete(messageType) {
      this.selectedResponse = messageType;
      if (messageType.automation_rules.length || messageType.in_used.length) {
        this.showDeleteWithWarningModal = true;
        return;
      }
      this.showDeletePopup = true;
    },
    confirmDeletion() {
      this.loading[this.selectedResponse.id] = true;
      this.deleteMessageType(this.selectedResponse.id);
      this.closeDelete();
    },
    closeDelete() {
      this.selectedResponse = null;
      this.showDeletePopup = false;
      this.showDeleteWithWarningModal = false;
    },
    async deleteMessageType(id) {
      try {
        await this.$store.dispatch('messageTypes/delete', id);
        bus.$emit(
          'newToastMessage',
          this.$t('MESSAGE_TYPE.API.DELETE.SUCCESS_MESSAGE')
        );
      } catch (error) {
        bus.$emit(
          'newToastMessage',
          this.$t('MESSAGE_TYPE.API.DELETE.ERROR_MESSAGE')
        );
      } finally {
        this.selectedPageNumber = 1;
        this.loading[id] = false;
      }
    },
    onPageChange(page) {
      this.selectedPageNumber = page;
      this.fetchMessageTypes();
    },
  },
};
</script>

<style lang="scss" scoped>
.table-container {
  padding: var(--space-large) var(--space-large);
}
</style>
