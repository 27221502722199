import { frontendURL } from '../../helper/URLHelper';
import EditContactAttributes from './EditContactAttributes.vue';
import Successful from './Successful.vue';

export default {
  routes: [
    {
      path: frontendURL(
        'accounts/:accountId/contact/:contactId/attributes/edit'
      ),
      name: 'edit_contact_attributes',
      roles: ['administrator'],
      component: EditContactAttributes,
    },
    {
      path: frontendURL(
        'accounts/:accountId/contact/:contactId/attributes/success'
      ),
      name: 'edit_contact_attributes_successful',
      roles: ['administrator'],
      component: Successful,
    },
  ],
};
