<template>
  <div class="crop-image-container">
    <vue-cropper ref="cropper" alt="Source Image" :src="imgSrc" />
    <div class="crop-button text-center">
      <woot-button type="button" @click="onCrop">
        {{ $t('MESSAGE_TYPE.FORM.IMAGE.CROP_BUTTON') }}
      </woot-button>
    </div>
  </div>
</template>

<script>
import VueCropper from 'vue-cropperjs';
import 'cropperjs/dist/cropper.css';
import WootButton from './WootButton.vue';

export default {
  components: { VueCropper, WootButton },
  props: {
    imgSrc: {
      type: String,
      default: '',
    },
  },
  methods: {
    onCrop() {
      this.$emit('on-crop', this.$refs.cropper.getCroppedCanvas().toDataURL());
    },
  },
};
</script>

<style lang="scss" scoped>
.crop-image-container {
  padding: var(--space-medium);
  display: flex;
  flex-direction: column;
  gap: var(--space-medium);
}
</style>
