/* global axios */
import * as MutationHelpers from 'shared/helpers/vuex/mutationHelpers';
import types from '../mutation-types';

export const state = {
  records: [],
  accountCustomAttributes: null,
  uiFlags: {
    isFetching: false,
    isCreating: false,
    isDeleting: false,
    isUpdating: false,
  },
};

export const getters = {
  getContactAttributes(_state) {
    return _state.records;
  },
  getUIFlags(_state) {
    return _state.uiFlags;
  },
  getAccountCustomAttributes(_state) {
    return _state.accountCustomAttributes;
  },
};

const accountIdFromRoute = () => {
  const isInsideAccountScopedURLs = window.location.pathname.includes(
    '/app/accounts'
  );

  if (isInsideAccountScopedURLs) {
    return window.location.pathname.split('/')[3];
  }

  return '';
};

export const actions = {
  get: async function getContactAttributes({ commit }) {
    commit(types.SET_CONTACT_ATTRIBUTES_UI_FLAG, { isFetching: true });
    try {
      const accountId = accountIdFromRoute();
      const response = await axios.get(
        `/public/api/v1/accounts/${accountId}/contact_attributes`
      );
      commit(types.SET_CONTACT_ATTRIBUTES, response.data.contact_attributes);
      commit(
        types.SET_CONTACT_ATTRIBUTES_ACCOUNT,
        response.data.account_custom_attributes
      );
      commit(types.SET_CONTACT_ATTRIBUTES_UI_FLAG, { isFetching: false });
    } catch (error) {
      commit(types.SET_CONTACT_ATTRIBUTES_UI_FLAG, { isFetching: false });
    } finally {
      commit(types.SET_CONTACT_ATTRIBUTES_UI_FLAG, { isFetching: false });
    }
  },
  update: async function updateContactAttribute(
    { commit },
    { customAttributes, accountId, contactId, phoneNumber }
  ) {
    try {
      commit(types.SET_CONTACT_ATTRIBUTES_UI_FLAG, { isUpdating: true });
      await axios.patch(
        `/public/api/v1/accounts/${accountId}/contact_attributes/${contactId}`,
        { custom_attributes: customAttributes, phone_number: phoneNumber }
      );
      commit(types.SET_CONTACT_ATTRIBUTES_UI_FLAG, { isUpdating: false });
    } catch (error) {
      commit(types.SET_CONTACT_ATTRIBUTES_UI_FLAG, { isUpdating: false });
      throw error;
    } finally {
      commit(types.SET_CONTACT_ATTRIBUTES_UI_FLAG, { isUpdating: false });
    }
  },
};

export const mutations = {
  [types.SET_CONTACT_ATTRIBUTES_UI_FLAG](_state, data) {
    _state.uiFlags = {
      ..._state.uiFlags,
      ...data,
    };
  },
  [types.SET_CONTACT_ATTRIBUTES]: MutationHelpers.set,
  [types.EDIT_CONTACT_ATTRIBUTES]: MutationHelpers.update,
  [types.SET_CONTACT_ATTRIBUTES_ACCOUNT](_state, data) {
    _state.accountCustomAttributes = data;
  },
};

export default {
  namespaced: true,
  actions,
  state,
  getters,
  mutations,
};
