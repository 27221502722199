export const actionDropDown = type => {
  const baseOption = [
    {
      label: 'MESSAGE_TYPE.FORM.BUTTON.PAYLOAD.TEXT.LABEL',
      value: 'text',
    },
    {
      label: 'MESSAGE_TYPE.FORM.BUTTON.PAYLOAD.SEND_MESSAGE_TYPE.LABEL',
      value: 'send_message_type',
    },
  ];

  if (type !== 'quick_reply') {
    baseOption.push({
      label: 'MESSAGE_TYPE.FORM.BUTTON.PAYLOAD.URL.LABEL',
      value: 'url',
    });
  }
  return baseOption;
};

export const buttonTypeDropDown = () => {
  return [
    {
      label: 'Main Button',
      value: 'primary',
    },
  ];
};
