<template>
  <woot-modal :show="show" :on-close="close">
    <woot-modal-header :header-title="$t('MESSAGE_TYPE.PREVIEW')" />
    <div ref="previewContainer" class="preview-container">
      <div
        v-for="(message, index) in messages"
        :key="`message_content_${index}`"
        :class="{ sender: message.isSender, message: true }"
      >
        <div
          v-if="message.content"
          :class="{ 'message-content': true, 'is-sender': message.isSender }"
        >
          {{ message.content }}
        </div>
        <div class="action-type-container">
          <div
            v-for="(template, messageTypeIndex) in message.content_attributes"
            :key="messageTypeIndex"
          >
            <bubble-card-message
              v-if="
                message.type === 'card_message' || message.type === 'carousel'
              "
              :title.sync="template.title"
              :subtitle.sync="template.subtitle"
              :cover-image="template.image_url"
              :buttons="template.buttons || []"
              @on-click-button="clickButtonInMessageType"
            />
            <bubble-quick-reply
              v-if="message.type === 'quick_reply'"
              :title="template.title"
              :buttons="template.buttons || []"
              @on-click-button="clickButtonInMessageType"
            />
          </div>
        </div>
      </div>
    </div>
    <p class="reference">{{ $t('MESSAGE_TYPE.PREVIEW_REFERENCE') }}</p>
  </woot-modal>
</template>

<script>
import BubbleCardMessage from '../../../../../dashboard/components/widgets/conversation/bubble/CardMessage.vue';
import BubbleQuickReply from '../../../../../dashboard/components/widgets/conversation/bubble/QuickReply.vue';
export default {
  components: { BubbleCardMessage, BubbleQuickReply },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    onClose: {
      type: Function,
      default: () => {},
    },
    messageType: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      messages: [],
    };
  },
  computed: {
    isCardMessage() {
      return this.messageType.type === 'card_message';
    },
    isQuickReply() {
      return this.messageType.type === 'quick_reply';
    },
  },
  watch: {
    show: {
      handler() {
        if (!this.show) {
          this.messages = [];
          return;
        }

        if (
          this.messageType.initialMessage &&
          this.messageType.type === 'quick_reply'
        ) {
          this.messages.push({
            isSender: false,
            content: this.messageType.initialMessage,
            content_attributes: this.messageType.templates,
            type: 'text',
          });
        }

        this.messages.push({
          isSender: false,
          content: null,
          content_attributes: this.messageType.templates,
          type: this.messageType.type,
        });
      },
      immediate: true,
    },
  },
  methods: {
    close() {
      this.$emit('on-close');
    },
    async clickButtonInMessageType(button) {
      if (
        ['postback', 'send_message_type', 'text'].includes(
          button.action.type
        ) &&
        button.action[button.action.type.toString()] !== ''
      ) {
        const data = await this.$store.dispatch(
          'messageTypes/getAllMessageTypes',
          button.action[button.action.type.toString()]
        );

        if (button.action[button.action.type.toString()]) {
          this.messages.push({
            content: button.action[button.action.type.toString()],
            isSender: false,
            type: 'text',
          });
        }

        if (data.length) {
          const messageType = data[0];
          this.messages.push({
            content: null,
            isSender: false,
            type: messageType.type,
            content_attributes: messageType.payload,
          });
        }

        setTimeout(() => {
          this.scrollToBottom();
        }, 100);
      } else {
        window.open(button.action[button.action.type.toString()], '_blank');
      }
    },
    scrollToBottom() {
      const container = this.$refs.previewContainer;
      container.scrollTop = container.scrollHeight;
    },
  },
};
</script>

<style lang="scss" scoped>
.preview-container {
  display: flex;
  height: 600px;
  padding: 12px;
  flex-direction: column;
  align-items: flex-end;
  gap: 8px;
  align-self: stretch;
  overflow: auto;
  .sender {
    justify-content: end;
  }
  .message-content {
    &.is-sender {
      border-radius: 20px 20px 0px 20px;
    }
    padding: 12px 16px;
    border-radius: 0px 20px 20px 20px;
    border: 1px solid var(--w-500);
    background: var(--w-500);
    color: #fff;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
  }
  .message {
    width: 100%;
    overflow: unset;
    display: flex;

    > .action-type-container {
      display: flex;
      overflow: auto;
      gap: 8px;
    }
  }
}
.reference {
  display: flex;
  width: 560px;
  padding: 20px 0px;
  flex-direction: column;
  align-items: center;
  gap: 28px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}
</style>
