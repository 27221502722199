<template>
  <modal :show.sync="show" :on-close="onClose">
    <div class="h-auto overflow-auto flex flex-col">
      <woot-modal-header :header-title="pageTitle" />
      <form class="flex flex-col w-full" @submit.prevent="editCannedResponse()">
        <div class="w-full">
          <label :class="{ error: $v.shortCode.$error }">
            {{ $t('CANNED_MGMT.EDIT.FORM.SHORT_CODE.LABEL') }}
            <input
              v-model.trim="shortCode"
              type="text"
              :placeholder="$t('CANNED_MGMT.EDIT.FORM.SHORT_CODE.PLACEHOLDER')"
              @input="$v.shortCode.$touch"
            />
          </label>
        </div>

        <div class="w-full">
          <label :class="{ error: $v.content.$error }">
            {{ $t('CANNED_MGMT.EDIT.FORM.CONTENT.LABEL') }}
          </label>
          <div class="editor-wrap">
            <woot-message-editor
              v-model="content"
              class="message-editor"
              :class="{ editor_warning: $v.content.$error }"
              :enable-variables="true"
              :enable-canned-responses="false"
              :placeholder="$t('CANNED_MGMT.EDIT.FORM.CONTENT.PLACEHOLDER')"
              @blur="$v.content.$touch"
            />
          </div>
        </div>

        <div class="medium-12 columns">
          <label>
            <span>{{ $t('CANNED_MGMT.ADD.FORM.IMAGES.LABEL') }}</span>
            <input
              id="images"
              ref="images"
              type="file"
              accept="image/png, image/jpeg, image/jpg"
              multiple="true"
              @change="selectImages"
            />
          </label>
          <div class="canned-response-images">
            <div
              v-for="(selectedImage, index) in selectedImages"
              :key="index"
              class="canned-response-image-container"
            >
              <div
                :style="{ backgroundImage: `url(${selectedImage.image})` }"
                class="canned-response-image"
              />
              <p class="filename">{{ selectedImage.fileName }}</p>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <div class="medium-12 columns">
            <woot-submit-button
              :disabled="
                $v.content.$invalid ||
                $v.shortCode.$invalid ||
                editCanned.showLoading
              "
              :button-text="$t('CANNED_MGMT.EDIT.FORM.SUBMIT')"
              :loading="editCanned.showLoading"
            />
            <button class="button clear" @click.prevent="onClose">
              {{ $t('CANNED_MGMT.EDIT.CANCEL_BUTTON_TEXT') }}
            </button>
          </div>
        </div>
      </form>
    </div>
  </modal>
</template>

<script>
/* eslint no-console: 0 */
import { required, minLength } from 'vuelidate/lib/validators';
import WootMessageEditor from 'dashboard/components/widgets/WootWriter/Editor';
import WootSubmitButton from '../../../../components/buttons/FormSubmitButton';
import alertMixin from 'shared/mixins/alertMixin';
import Modal from '../../../../components/Modal';

export default {
  components: {
    WootSubmitButton,
    Modal,
    WootMessageEditor,
  },
  mixins: [alertMixin],
  props: {
    id: { type: Number, default: null },
    edcontent: { type: String, default: '' },
    edshortCode: { type: String, default: '' },
    edImages: { type: Array, default: () => [] },
    onClose: { type: Function, default: () => {} },
  },
  data() {
    return {
      editCanned: {
        showAlert: false,
        showLoading: false,
      },
      shortCode: this.edshortCode,
      content: this.edcontent,
      show: true,
      selectedImages: this.edImages.map(image => ({
        image: image.file_url,
        fileName: image.filename,
      })),
    };
  },
  validations: {
    shortCode: {
      required,
      minLength: minLength(2),
    },
    content: {
      required,
    },
  },
  computed: {
    pageTitle() {
      return `${this.$t('CANNED_MGMT.EDIT.TITLE')} - ${this.edshortCode}`;
    },
    cannedResponseImages() {
      const images = this.edImages.map(image => ({
        image: image.file_url,
        fileName: image.filename,
      }));

      return images;
    },
  },
  methods: {
    setPageName({ name }) {
      this.$v.content.$touch();
      this.content = name;
    },
    resetForm() {
      this.shortCode = '';
      this.content = '';
      this.$v.shortCode.$reset();
      this.$v.content.$reset();
    },
    editCannedResponse() {
      // Show loading on button
      this.editCanned.showLoading = true;
      // Make API Calls
      const formData = new FormData();
      formData.append('canned_response[id]', this.id);
      formData.append('canned_response[short_code]', this.shortCode);
      formData.append('canned_response[content]', this.content);
      this.$refs.images.files.forEach(file =>
        formData.append('canned_response[images][]', file)
      );
      this.$store
        .dispatch('cannedResponse/updateCannedResponse', {
          id: this.id,
          updateObj: formData,
        })
        .then(() => {
          // Reset Form, Show success message
          this.editCanned.showLoading = false;
          this.showAlert(this.$t('CANNED_MGMT.EDIT.API.SUCCESS_MESSAGE'));
          this.resetForm();
          setTimeout(() => {
            this.onClose();
          }, 10);
        })
        .catch(error => {
          this.editCanned.showLoading = false;
          const errorMessage =
            error?.message || this.$t('CANNED_MGMT.EDIT.API.ERROR_MESSAGE');
          this.showAlert(errorMessage);
        });
    },
    selectImages(event) {
      const input = event.target;

      if (!input.files) return;
      if (this.validateFiles(input.files)) {
        this.showAlert(this.$t('CANNED_MGMT.ADD.FORM.IMAGES.ERROR'));
        this.$refs.images.value = null;
        this.selectedImages = [];
        return;
      }

      this.selectedImages = [];

      [...input.files].forEach(fileList => {
        const reader = new FileReader();
        const fileName = fileList.name;
        reader.onload = e => {
          const payload = {
            image: e.target.result,
            fileName,
          };

          this.selectedImages.push(payload);
        };
        reader.readAsDataURL(fileList);
      });
    },
    validateFiles(files) {
      return files.length > 4 || [...files].some(file => file.size > 1e6);
    },
  },
};
</script>
<style scoped lang="scss">
::v-deep {
  .ProseMirror-menubar {
    @apply hidden;
  }

  .ProseMirror-woot-style {
    @apply min-h-[12.5rem];

    p {
      @apply text-base;
    }
  }
}
</style>
