import Automation from './Index';
import AddAutomation from './AddAutomationRule.vue';
import EditAutomationRule from './EditAutomationRule.vue';
import { frontendURL } from '../../../helper/URLHelper';

export default {
  routes: [
    {
      path: frontendURL('accounts/:accountId/automation'),
      name: 'automation',
      roles: ['administrator', 'supervisor', 'agent'],
      component: Automation,
    },
    {
      path: frontendURL('accounts/:accountId/automation/new'),
      name: 'new_automation',
      roles: ['administrator', 'supervisor', 'agent'],
      component: AddAutomation,
    },
    {
      path: frontendURL('accounts/:accountId/automation/:automationId/edit'),
      name: 'edit_automation',
      roles: ['administrator', 'supervisor', 'agent'],
      component: EditAutomationRule,
    },
    // {
    //   path: frontendURL('accounts/:accountId/automation'),
    //   component: SettingsContent,
    //   props: {
    //     headerTitle: 'AUTOMATION.HEADER',
    //     icon: 'automation',
    //     showNewButton: false,
    //   },
    //   children: [
    //     {
    //       path: '',
    //       name: 'automation_wrapper',
    //       redirect: 'list',
    //     },
    //     {
    //       path: 'list',
    //       name: 'automation_list',
    //       component: Automation,
    //       roles: ['administrator'],
    //     },
    //   ],
    // },
  ],
};
