<template>
  <div class="d-flex w-max mt-1 mb-1 gap-1">
    <woot-button
      color-scheme="secondary"
      size="tiny"
      icon="vertical-sort"
      viewBox="0 0 12 12"
      variant="smooth"
      @click="handleClickSort"
    >
      {{ $t('SORTING.BUTTON_LABEL') }}
    </woot-button>
    <woot-button
      v-if="sort"
      color-scheme="primary"
      size="tiny"
      type="blue"
      icon="checkmark"
      @click="confirm"
    >
      {{ $t('SORTING.SAVE') }}
    </woot-button>
    <woot-button
      v-if="sort"
      color-scheme="primary"
      size="tiny"
      type="clear"
      variant="smooth"
      @click="handleClickSort"
    >
      {{ $t('SORTING.CANCEL') }}
    </woot-button>
  </div>
</template>

<script>
export default {
  props: {
    sort: {
      type: Boolean,
      default: false,
    },
    updateOrder: {
      type: Function,
      default: () => {},
    },
    toggleSort: {
      type: Function,
      default: () => {},
    },
  },
  methods: {
    confirm() {
      this.$emit('update-order');
    },
    handleClickSort() {
      this.$emit('toggle-sort');
    },
  },
};
</script>
