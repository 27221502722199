import types from '../mutation-types';
import MessageTypeAPI from '../../api/messageTypes';
import * as MutationHelpers from 'shared/helpers/vuex/mutationHelpers';
import { throwErrorMessage } from '../utils/api';
import Vue from 'vue';
export const state = {
  meta: {
    count: 0,
    currentPage: 1,
  },
  records: [],
  errors: null,
  uiFlags: {
    isFetchingItem: false,
    isFetching: false,
    isCreating: false,
    isDeleting: false,
    isUpdating: false,
    isExecuting: false,
  },
};

export const getters = {
  getMessageTypes($state) {
    return $state.records;
  },
  getMessageType: $state => id => {
    return $state.records.find(record => record.id === Number(id));
  },
  getUIFlags($state) {
    return $state.uiFlags;
  },
  getErrors($state) {
    return $state.errors;
  },
  getMeta($state) {
    return $state.meta;
  },
};

export const actions = {
  get: async function getMessageTypes(
    { commit },
    { per = 10, page = 1, title = '', keyword = '', without_inactive = false }
  ) {
    commit(types.SET_MESSAGE_TYPES_UI_FLAG, { isFetching: true });
    try {
      const response = await MessageTypeAPI.get(
        per,
        page,
        keyword,
        title,
        without_inactive
      );

      commit(types.SET_MESSAGE_TYPES, response.data.payload);
      commit(types.SET_MESSAGE_TYPES_META, response.data.meta);
    } catch (error) {
      throwErrorMessage(error);
    } finally {
      commit(types.SET_MESSAGE_TYPES_UI_FLAG, { isFetching: false });
    }
  },
  getSingleMessageType: async function getMessageTypeById(
    { commit },
    messageTypeId
  ) {
    commit(types.SET_MESSAGE_TYPES_UI_FLAG, { isFetchingItem: true });
    try {
      const response = await MessageTypeAPI.show(messageTypeId);
      commit(types.ADD_MESSAGE_TYPE, response.data.payload);
    } catch (error) {
      throwErrorMessage(error);
    } finally {
      commit(types.SET_MESSAGE_TYPES_UI_FLAG, { isFetchingItem: false });
    }
  },
  search: async function searchMessageType({ commit }, keyword) {
    commit(types.SET_MESSAGE_TYPES_UI_FLAG, { isFetching: true });

    try {
      const response = await MessageTypeAPI.getWithParams({ title: keyword });
      commit(types.SET_MESSAGE_TYPES, response.data.payload);
      commit(types.SET_MESSAGE_TYPES_META, response.data.meta);
    } catch (error) {
      throwErrorMessage(error);
    } finally {
      commit(types.SET_MESSAGE_TYPES_UI_FLAG, { isFetching: false });
    }
  },
  getAllMessageTypes: async function getAllMessageTypes({ commit }, keyword) {
    commit(types.SET_MESSAGE_TYPES_UI_FLAG, { isExecuting: true });

    try {
      const response = await MessageTypeAPI.getWithParams({
        keyword,
        per: 100,
      });
      return response.data.payload;
    } catch (error) {
      return throwErrorMessage(error);
    } finally {
      commit(types.SET_MESSAGE_TYPES_UI_FLAG, { isExecuting: false });
    }
  },
  uploadAttachment: async function attachFile({ commit }, file) {
    commit(types.SET_MESSAGE_TYPES_UI_FLAG, { isExecuting: true });
    try {
      const response = await MessageTypeAPI.attachment(file);
      return response;
    } catch (error) {
      return throwErrorMessage(error);
    } finally {
      commit(types.SET_MESSAGE_TYPES_UI_FLAG, { isExecuting: false });
    }
  },
  create: async function createMessageType({ commit }, messageTypeObj) {
    commit(types.SET_MESSAGE_TYPES_UI_FLAG, { isCreating: true });
    try {
      const response = await MessageTypeAPI.create(messageTypeObj);
      commit(types.ADD_MESSAGE, response.data.payload);
      commit(types.SET_MESSAGE_TYPES_INVALID, null);
    } catch (error) {
      if (error?.response?.data?.error.keyword[0]) {
        commit(types.SET_MESSAGE_TYPES_INVALID, {
          keyword: error?.response?.data?.error.keyword[0],
        });
      }
      throwErrorMessage(error);
    } finally {
      commit(types.SET_MESSAGE_TYPES_UI_FLAG, { isCreating: false });
    }
  },
  update: async ({ commit }, { id, ...updateObj }) => {
    commit(types.SET_MESSAGE_TYPES_UI_FLAG, { isUpdating: true });
    try {
      const response = await MessageTypeAPI.update(id, updateObj);
      commit(types.EDIT_MESSAGE_TYPE, response.data.payload);
    } catch (error) {
      if (error?.response?.data?.error.keyword[0]) {
        commit(types.SET_MESSAGE_TYPES_INVALID, {
          keyword: error?.response?.data?.error.keyword[0],
        });
      }
      throwErrorMessage(error);
    } finally {
      commit(types.SET_MESSAGE_TYPES_UI_FLAG, { isUpdating: false });
    }
  },
  delete: async ({ commit }, id) => {
    commit(types.SET_MESSAGE_TYPES_UI_FLAG, { isDeleting: true });
    try {
      const response = await MessageTypeAPI.delete(id);
      commit(types.SET_MESSAGE_TYPES, response.data.payload);
    } catch (error) {
      throwErrorMessage(error);
    } finally {
      commit(types.SET_MESSAGE_TYPES_UI_FLAG, { isDeleting: false });
    }
  },
};

export const mutations = {
  [types.SET_MESSAGE_TYPES_UI_FLAG](_state, data) {
    _state.uiFlags = {
      ..._state.uiFlags,
      ...data,
    };
  },

  [types.ADD_MESSAGE_TYPE]: MutationHelpers.create,
  [types.SET_MESSAGE_TYPES]: MutationHelpers.set,
  [types.EDIT_MESSAGE_TYPE]: MutationHelpers.update,
  [types.DELETE_MESSAGE_TYPE]: MutationHelpers.destroy,
  [types.SET_MESSAGE_TYPES_INVALID](_state, data) {
    _state.errors = data;
  },
  [types.SET_MESSAGE_TYPES_META]: ($state, data) => {
    const { count, current_page: currentPage } = data;
    Vue.set($state.meta, 'count', count);
    Vue.set($state.meta, 'currentPage', currentPage);
  },
};

export default {
  namespaced: true,
  actions,
  state,
  getters,
  mutations,
};
