<template>
  <div
    class="flex flex-1 h-full justify-between flex-col m-0 bg-slate-25 dark:bg-slate-900"
  >
    <message-type-header :page-title="$t(`MESSAGE_TYPE.${mode}.TITLE`)">
      <woot-button variant="hollow" type="button" @click="showPreviewModal">
        {{ $t('MESSAGE_TYPE.PREVIEW') }}
      </woot-button>
      <woot-button :is-disabled="disabledSubmit" @click="submitForm">
        {{ $t('MESSAGE_TYPE.SAVE') }}
      </woot-button>
    </message-type-header>
    <woot-loading-state
      v-if="false"
      :message="$t('MESSAGE_TYPE.EDITOR.LOADING')"
    />
    <div class="flex-1 overflow-auto p-4">
      <message-type-form
        v-if="!uiFlags.isFetchingItem"
        ref="message_type_form"
        :message-type-data="messageType"
        :preview-modal.sync="previewModal"
        :message-types="messageTypes"
        @on-close-preview-modal="onOnClosePreviewModal"
        @submit="submit"
        @validation-state="handleValidationState"
      />
    </div>
  </div>
</template>

<script>
import WootButton from '../../../../components/ui/WootButton.vue';
import MessageTypeHeader from '../components/MessageTypeHeader.vue';
import MessageTypeForm from '../components/MessageTypeForm.vue';
import alertMixin from 'shared/mixins/alertMixin';
import { mapGetters } from 'vuex';

export default {
  components: {
    MessageTypeHeader,
    MessageTypeForm,
    WootButton,
  },
  mixins: [alertMixin],
  data() {
    return {
      messageType: {
        title: '',
        keyword: '',
        initialMessage: '',
        type: 'card_message',
        templates: [
          {
            buttons: [
              {
                buttonType: 'primary',
                label: '',
                hide: false,
                action: {
                  hide: false,
                  type: 'text',
                  postback: '',
                  url: '',
                  text: '',
                  send_message_type: '',
                },
              },
            ],
          },
        ],
      },
      mode: 'CREATE',
      disabledSubmit: true,
      previewModal: false,
    };
  },
  computed: {
    ...mapGetters({
      uiFlags: 'messageTypes/getUIFlags',
      messageTypes: 'messageTypes/getMessageTypes',
    }),
    messageTypeId() {
      return this.$route.params.messageTypeId;
    },
    messageTypeName() {
      return this.$route.query.type || this.messageType?.type;
    },
    isQuickReplyType() {
      return this.messageTypeName === 'quick_reply';
    },
    isCardMessage() {
      return (
        this.messageTypeName === 'card_message' ||
        this.messageTypeName === 'carousel'
      );
    },
    messageTypeUpperCase() {
      return this.messageTypeName.toUpperCase();
    },
    cardMessageTemplate() {
      if (!this.mode === 'CREATE') return [];
      return [
        {
          id: 1,
          imageUploaded: false,
          title: this.$t(
            `MESSAGE_TYPE.TEMPLATE_PREVIEW.${this.messageTypeUpperCase}.TITLE`
          ),
          image_url: '/assets/images/dashboard/message-types/card-bg.png',
          type: this.messageTypeName,
          initialMessage: '',
          buttons: [
            {
              buttonType: 'primary',
              label: this.$t(
                'MESSAGE_TYPE.TEMPLATE_PREVIEW.CARD_MESSAGE.BUTTON_LABEL'
              ),
              action: {
                hide: false,
                type: 'text',
                postback: '',
                url: '',
                text: '',
                send_message_type: '',
              },
            },
            {
              buttonType: 'primary',
              label: this.$t(
                'MESSAGE_TYPE.TEMPLATE_PREVIEW.CARD_MESSAGE.BUTTON_LABEL'
              ),
              action: {
                hide: true,
                type: 'text',
                postback: '',
                url: '',
                text: '',
                send_message_type: '',
              },
            },
          ],
        },
      ];
    },
    quickReplyTemplate() {
      if (!this.mode === 'CREATE') return [];
      return [
        {
          id: 1,
          title: this.$t(
            `MESSAGE_TYPE.TEMPLATE_PREVIEW.${this.messageTypeUpperCase}.TITLE`
          ),
          type: this.messageTypeName,
          initialMessage: '',
          buttons: [
            {
              buttonType: 'primary',
              hide: false,
              label: this.$t(
                `MESSAGE_TYPE.TEMPLATE_PREVIEW.${this.messageTypeUpperCase}.BUTTON_LABEL`
              ),
              action: {
                hide: false,
                type: 'text',
                postback: '',
                url: '',
                text: '',
                send_message_type: '',
              },
            },
          ],
        },
      ];
    },
  },
  watch: {
    $route: {
      handler() {
        if (this.$route.params.messageTypeId) {
          this.fetchMessageType();
        } else {
          this.initMessageType();
        }
      },
      immediate: true,
    },
  },
  mounted() {
    const validType = ['card_message', 'quick_reply'];
    if (
      !validType.includes(this.messageTypeName) &&
      !this.$route.params.messageTypeId
    ) {
      this.$router.push({
        name: 'message_types',
      });
    }

    this.$store.dispatch('messageTypes/get', { per: 100, page: 1 });
  },
  methods: {
    fetchMessageType() {
      this.mode = 'EDIT';
      this.manifestMessageType();
    },
    async manifestMessageType() {
      await this.$store.dispatch(
        'messageTypes/getSingleMessageType',
        this.messageTypeId
      );
      const messageType = this.$store.getters['messageTypes/getMessageType'](
        this.messageTypeId
      );

      this.messageType = this.formatMessageType(messageType);
    },
    formatMessageType(messageType) {
      return {
        id: messageType.id,
        title: messageType.title,
        keyword: messageType.keyword,
        type: this.getMessageType(messageType.type),
        active: messageType.active,
        initialMessage: messageType.initial_message,
        templates: ['card_message', 'carousel'].includes(messageType.type)
          ? this.getCardMessageContentAttributes(messageType.payload)
          : this.getQuickReplyContentAttributes(messageType.payload),
      };
    },
    getCardMessageContentAttributes(templates) {
      return templates.map((content, index) => {
        return {
          ...content,
          buttons:
            content.buttons.length === 2
              ? content.buttons
              : content.buttons.concat({
                  buttonType: 'primary',
                  label: this.$t(
                    'MESSAGE_TYPE.TEMPLATE_PREVIEW.CARD_MESSAGE.BUTTON_LABEL'
                  ),
                  action: {
                    hide: true,
                    type: 'text',
                    postback: '',
                    url: '',
                    text: '',
                    send_message_type: '',
                  },
                }),
          id: index + 1,
          imageUploaded: !!content.image_url,
        };
      });
    },
    getQuickReplyContentAttributes(templates) {
      return templates[0].buttons.map((button, index) => ({
        ...templates[0],
        buttons: [
          {
            ...button,
            id: index + 1,
            action: { hide: false, ...button.action },
          },
        ],
      }));
    },
    getMessageType(type) {
      if (type === 'carousel') {
        return 'card_message';
      }

      return type;
    },
    handleValidationState(validateState) {
      this.disabledSubmit = validateState.$invalid;
    },
    submitForm() {
      this.$refs.message_type_form.$refs.form.click();
    },
    initMessageType() {
      this.mode = 'CREATE';
      this.messageType = {
        title: '',
        keyword: '',
        initialMessage: '',
        active: true,
        type: this.messageTypeName,
        templates: this.isQuickReplyType
          ? this.quickReplyTemplate
          : this.cardMessageTemplate,
      };
    },

    submit(messageTypeData) {
      if (this.mode === 'CREATE') {
        this.createMessageType(messageTypeData);
      } else {
        this.updateMessageType(messageTypeData);
      }
    },
    async createMessageType(messageType) {
      try {
        await this.$store.dispatch('messageTypes/create', messageType);
        this.showAlert(this.$t('MESSAGE_TYPE.API.ADD.SUCCESS_MESSAGE'));
        this.$router.push({ name: 'automation' });
      } catch (error) {
        this.showAlert(this.$t('MESSAGE_TYPE.API.ERROR'));
      }
    },
    async updateMessageType(messageType) {
      try {
        await this.$store.dispatch('messageTypes/update', messageType);
        this.showAlert(this.$t('MESSAGE_TYPE.API.EDIT.SUCCESS_MESSAGE'));
        this.$router.push({ name: 'automation' });
      } catch (error) {
        this.showAlert(this.$t('MESSAGE_TYPE.API.ERROR'));
      }
    },
    showPreviewModal() {
      this.previewModal = !this.previewModal;
    },
    onOnClosePreviewModal() {
      this.previewModal = false;
    },
  },
};
</script>

<style scoped lang="scss">
.wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
}
</style>
