<template>
  <multiselect
    v-model="selectedOption"
    class="message-type-multiselect"
    :options="options"
    :allow-empty="false"
    :option-height="104"
    :placeholder="$t('FORMS.MULTISELECT.SELECT')"
    label="name"
    track-by="keyword"
  >
    <template slot="singleLabel" slot-scope="props">
      <span class="option__desc">
        <span class="option__title">{{ props.option.name }}</span>
        <span v-if="props.option.tag" :class="getTagClass(props.option.tag)">
          {{ getActionTypeTag(props.option.tag) }}
        </span>
      </span>
    </template>
    <template slot="option" slot-scope="props">
      <div class="option__desc">
        <span class="option__title">{{ props.option.name }}</span>
        <span v-if="props.option.tag" :class="getTagClass(props.option.tag)">
          {{ getActionTypeTag(props.option.tag) }}
        </span>
      </div>
    </template>
  </multiselect>
</template>

<script>
import messageTypeMixin from '../mixins/messageTypeMixin';

export default {
  mixins: [messageTypeMixin],
  props: {
    dataSelected: {
      type: String,
      default: '',
    },
    options: {
      type: Array,
      default: () => [],
    },
    index: {
      type: Number,
      default: 1,
    },
  },
  computed: {
    selectedOption: {
      get() {
        return this.options.find(
          option => option.keyword === this.dataSelected
        );
      },
      set(newValue) {
        const value = newValue.keyword || '';
        this.$emit('update:data-selected', value);
      },
    },
    value() {
      return this.options.find(option => option.value === this.dataSelected);
    },
  },
  methods: {
    onMultiselectInput(newValue) {
      const value = newValue.value || '';
      this.$emit('update:data-selected', value);
    },
  },
};
</script>

<style lang="scss">
.message-type-multiselect {
  .multiselect__tags,
  .multiselect__select {
    min-height: unset;
    max-height: 40px;
    height: 40px;
  }

  .multiselect__input {
    height: 35px;
  }

  .multiselect__single,
  .multiselect__placeholder {
    @apply flex items-center h-full;
    padding: unset;
  }

  .option__tag {
    border-radius: 20px;
    padding: 4px 8px;
    font-size: 10px;

    &.card_message,
    &.carousel {
      background: rgba(31, 147, 255, 0.15);
      color: #1f93ff;
    }

    &.quick_reply {
      background: rgba(0, 162, 0, 0.15);
      color: #00a200;
    }
  }
}
</style>
