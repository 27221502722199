import { frontendURL } from '../../../helper/URLHelper';
import BookingView from './BookingView.vue';
import ServiceView from './ServiceView.vue';
import AvailabilityView from './AvailabilityView.vue';
import BookingDashboard from './BookingDashboard.vue';

export default {
  routes: [
    {
      path: frontendURL('accounts/:accountId/booking_management'),
      name: 'booking_management',
      roles: ['administrator', 'supervisor', 'agent'],
      component: BookingView,
    },
    {
      path: frontendURL('accounts/:accountId/service_management'),
      name: 'service_management',
      roles: ['administrator', 'supervisor', 'agent'],
      component: ServiceView,
    },
    {
      path: frontendURL('accounts/:accountId/availability'),
      name: 'availability',
      roles: ['administrator', 'supervisor', 'agent'],
      component: AvailabilityView,
    },
    {
      path: frontendURL('accounts/:accountId/calendar'),
      name: 'booking_calendar',
      roles: ['administrator', 'supervisor', 'agent'],
      component: BookingDashboard,
    },
  ],
};
